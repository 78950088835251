import { layerToLinestyle } from "../utils/CanvasUtils";
import { Polyline, Polygon, Group } from "../utils/Geometry";
import { SelectedDesignObject } from "../components/Canvas";
import { fadedOpacity, selectedColor } from "../utils/CanvasConstants";
import { useMemo, useState } from "react";
import { BlockProps, getConnectedSegments } from "../utils/DetailUtils";
import Hatch from "./Hatch";
import { getXYCrossing, Vector3To2DArray } from "../utils/GeomUtils";
import useProjectContext from "../hooks/useProjectContext";
import { isObjectOfType } from "../type/DesignatorObject";
import { _3DLineObject } from "../type/GeometryObject";
import { HatchType, Layer } from "../type/Layer";
import * as THREE from "three";

const BoundaryDetailBlock = ({
  block,
  u_flip,
  v_flip,
  flip_uv,
}: {
  block: BlockProps;
  u_flip?: boolean;
  v_flip?: boolean;
  flip_uv?: boolean;
}) => {
  const { project_context, setProjectContext } = useProjectContext();

  let drawn_object = project_context.project.get(
    block.drawn_object[block.active_object_index]
  );

  let thickness_material = project_context.project.get(
    block.materials[block.active_object_index]
  );
  let material = project_context.project.get(thickness_material.material);
  let boundary = block.points;

  // check layer activated
  let design_object_layer = isObjectOfType<_3DLineObject>(
    drawn_object,
    "_3DLineObject"
  )
    ? project_context.project.get(drawn_object.layer)
    : null;

  // is selected
  const is_selected =
    SelectedDesignObject(project_context)?.uuid === drawn_object.uuid;

  // is_scope
  const is_scope = block.drawn_object.some(
    (x) => x.uuid === project_context.scope?.uuid
  );

  // is faded
  const is_faded = !is_scope && project_context.scope !== null;

  // style override
  let style_override = {};
  if (is_faded) {
    style_override["opacity"] = fadedOpacity;
  }
  if (is_selected) {
    style_override["stroke"] = selectedColor;
    style_override["fill"] = selectedColor;
  }

  // active sides
  let active_sides = block.active_sides;

  // outline
  const outline_layer = useMemo(
    () => project_context.project.get(material.outline_layer),
    [material, project_context.project]
  );
  const linestyle = useMemo(
    () => layerToLinestyle(outline_layer, project_context.project),
    [outline_layer, project_context.project]
  );

  let active_segments = getConnectedSegments(boundary, active_sides);

  const SegmentLine = ({ segment }: { segment: number[] }) => {
    return (
      <Polygon
        layer={project_context.project.makeFixedRef(outline_layer)}
        points={segment}
        {...linestyle}
        {...style_override}
        closed={segment.length === 10}
        fill={null}
        strokeScaleEnabled={
          project_context.project.global_setting.rendering_style === "print"
        }
      />
    );
  };

  // hatch
  const hatch: HatchType = project_context.project.get(material.hatch);
  const hatch_layer: Layer = project_context.project.get(material.hatch_layer);

  function getPolygonMidpoint(points: number[]): [number, number] {
    let xSum = 0;
    let ySum = 0;
    const numPoints = points.length / 2; // Each point is defined by two values (x and y)

    for (let i = 0; i < points.length; i += 2) {
      xSum += points[i]; // x-coordinate
      ySum += points[i + 1]; // y-coordinate
    }

    const midpointX = xSum / numPoints;
    const midpointY = ySum / numPoints;

    return [midpointX, midpointY];
  }

  const hatch_props = useMemo(
    () => ({
      type: hatch?.value,
      boundary,
      pivot_point: hatch?.value.includes("Ins")
        ? block.active_object_index === 0
          ? getXYCrossing(
              u_flip !== flip_uv ? boundary[2].clone() : boundary[0].clone(),
              boundary[1].clone().sub(boundary[0])
            )
          : getXYCrossing(
              u_flip !== flip_uv ? boundary[3].clone() : boundary[1].clone(),
              boundary[2].clone().sub(boundary[1])
            )
        : new THREE.Vector3(0, 0, 0),
      unit_direction: hatch?.value.includes("Ins")
        ? block.active_object_index === 0
          ? boundary[1]
              .clone()
              .sub(boundary[0])
              .multiplyScalar(u_flip ? -1 : 1)
          : boundary[2]
              .clone()
              .sub(boundary[1])
              .multiplyScalar(v_flip ? -1 : 1)
        : new THREE.Vector3(1, 0, 0),
      scale: hatch?.value.includes("Ins")
        ? (thickness_material.thickness ?? 1) / 100
        : 1,
      layer: hatch_layer
        ? project_context.project.makeFixedRef(hatch_layer)
        : null,
      // debug: hatch?.value.includes("Ins"),
    }),
    [boundary, hatch, hatch_layer]
  );

  return (
    <Group>
      {design_object_layer.activated && outline_layer.activated
        ? active_segments.map((segment) => <SegmentLine segment={segment} />)
        : null}
      {design_object_layer.activated && hatch_layer?.activated && (
        <Hatch
          {...hatch_props}
          selected={is_selected}
          disabled={is_faded}
          useCopy={hatch?.value.includes("Ins")}
        />
      )}
      {is_selected && (
        <Polyline
          stroke={null}
          points={boundary}
          fill={selectedColor}
          strokeWidth={0}
          opacity={0.05}
          closed={true}
          listening={false}
        />
      )}
      {/* <Text
        x={getPolygonMidpoint(block.points)[0]}
        y={getPolygonMidpoint(block.points)[1]}
        text={`${block.grid_i}, ${block.grid_j}, ${block.active_object_index}`}
      /> */}
      {/* <Circle
        x={boundary[2]}
        y={boundary[3]}
        radius={3}
        stroke={"#aaaaaa"}
        strokeWidth={1}
      /> */}
    </Group>
  );
};

export const BlockClickArea = ({
  block,
  onClick,
}: {
  block: BlockProps;
  onClick: any;
}) => {
  const { project_context, setProjectContext } = useProjectContext();

  let drawn_object = project_context.project.get(
    block.drawn_object[block.active_object_index]
  );

  let material = project_context.project.get(
    block.materials[block.active_object_index]
  );
  let boundary = block.points;

  // check layer activated
  let design_object_layer = isObjectOfType<_3DLineObject>(
    drawn_object,
    "_3DLineObject"
  )
    ? project_context.project.get(drawn_object.layer)
    : null;

  // is selected
  const is_selected =
    SelectedDesignObject(project_context)?.uuid === drawn_object.uuid;

  // is_scope
  const is_scope = block.drawn_object.some(
    (x) => x.uuid === project_context.scope?.uuid
  );

  // is faded
  const is_faded = !is_scope && project_context.scope !== null;

  // style override
  let style_override = {};
  if (is_faded) {
    style_override["opacity"] = fadedOpacity;
  }
  if (is_selected) {
    style_override["stroke"] = selectedColor;
    style_override["fill"] = selectedColor;
  }
  const [opacity, setOpacity] = useState(0);

  const closed_boundary = [...boundary, boundary[0], boundary[1]];

  return design_object_layer.activated && is_scope ? (
    <Polygon
      block={block}
      layer={project_context.project.makeFixedRef(design_object_layer)}
      points={closed_boundary}
      closed={true}
      stroke={null}
      // fill={null}
      fill={selectedColor}
      opacity={opacity}
      onClick={onClick}
      onPointerEnter={() => setOpacity(0.1)}
      onPointerLeave={() => setOpacity(0)}
    />
  ) : null;
};

export default BoundaryDetailBlock;
