import useProjectContext from "../hooks/useProjectContext";
import { Layer } from "../type/Layer";
import { isNamedObject } from "../utils/ObjectUtils";
import IconSymbol from "./IconSymbol";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import PropertyRow from "./PropertyRow";

import styles from "./PropertySectionForLayer.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const PropertySectionForLayer = ({ data }: { data: Layer }) => {
  const { project_context } = useProjectContext();
  const color = project_context.project.get(data.color);
  return (
    <>
      <PropertyGroup title="Selection (1)">
        <PropertyRow>
          <PropertyBlock
            type="text-input-fill"
            name="Name"
            value={data.name}
            onChange={(event) =>
              project_context.project.update<Layer>(data, {
                name: event.target?.value,
              })
            }
          />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock
            type="object-selection"
            object_type="ColorStyle"
            value={isNamedObject(color) ? color.name : null}
            onClick={(e) =>
              project_context.project.update<Layer>(data, {
                color: project_context.project.makeFixedRef(e),
              })
            }
          >
            <div
              className={cx("frame-color")}
              style={{
                backgroundColor: project_context.project.get(data.color).value,
              }}
            />
          </PropertyBlock>
          <PropertyBlock
            type="action"
            icon_type="break"
            illust="Detach Style"
          />
          <PropertyBlock type="action" icon_type="match" illust="Match Style" />
          <PropertyBlock
            type="action"
            icon_type={data.activated ? "show" : "hide"}
            illust={data.activated ? "Shown" : "Hidden"}
            onClick={() => {
              project_context.project.update<Layer>(data, {
                activated: !data.activated,
              });
            }}
          />
          <PropertyBlock
            type="action"
            icon_type={data.locked ? "locked" : "unlocked"}
            illust={data.locked ? "Locked" : "Unlocked"}
            onClick={() => {
              project_context.project.update<Layer>(data, {
                locked: !data.locked,
              });
            }}
          />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock
            type="object-selection"
            object_type={"LineweightStyle"}
            value="CTB"
            illusts={[project_context.project.get(data.color).ctb_lineweight]}
          >
            <IconSymbol type="lineweight" />
          </PropertyBlock>
          <PropertyBlock
            type="action"
            icon_type="break"
            illust="Detach style"
          />
          <PropertyBlock type="action" icon_type="match" illust="Match Style" />
        </PropertyRow>
        <PropertyRow>
          <PropertyBlock
            type="object-selection"
            object_type={"LinetypeStyle"}
            value="SOLID"
          >
            <IconSymbol type="linetype" />
          </PropertyBlock>
          <PropertyBlock
            type="action"
            icon_type="break"
            illust="Detach style"
          />
          <PropertyBlock type="action" icon_type="match" illust="Match Style" />
        </PropertyRow>
      </PropertyGroup>
    </>
  );
};

export default PropertySectionForLayer;
