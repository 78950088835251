import MaterialObject from "./MaterialObject";

import { getGridBounds, getOffset, makeGrid } from "../utils/DetailUtils";
import useCanvasContext, { JointObject } from "../components/CanvasContext";
import { joinSegment, offsetSegment } from "../utils/GeomUtils";
import { Group } from "../utils/Geometry";
import { DrawnObject } from "../type/Project";
import { CrossSection, Detail } from "../type/Detail";
import useProjectContext from "../hooks/useProjectContext";
import { Abstract, isObjectOfType } from "../type/DesignatorObject";
import { _3DLineObject, ThicknessMaterialObject } from "../type/GeometryObject";
import React, { useEffect } from "react";
const DetailObject = ({
  drawn_object,
  detail,
  selected,
  is_locked,
  disabled,
}: {
  drawn_object: DrawnObject;
  detail: Abstract<Detail>;
  selected: boolean;
  is_locked: boolean;
  disabled?: boolean;
}) => {
  const { canvasContext } = useCanvasContext();
  const { project_context, setProjectContext } = useProjectContext();

  const getMaterialsAndBoundaries = () => {
    // line objects only
    if (!isObjectOfType<_3DLineObject>(drawn_object, "_3DLineObject")) {
      return { materials: [], boundaries: [] };
    }

    // no detail
    if (detail === null) {
      return { materials: [], boundaries: [] };
    }

    let cross_section = project_context.project.get(detail) as CrossSection;

    let thickness_materials = cross_section.materials
      ?.getElements()
      .map(project_context.project.get) as ThicknessMaterialObject[];

    let materials = thickness_materials.map((e) =>
      project_context.project.get(e.material)
    );

    let segment = drawn_object.geometry.points.slice();

    let offsets = getOffset(cross_section, thickness_materials);

    let joints = Object.values(canvasContext.jointObjects).filter(
      (joint) =>
        joint.anchors.filter(
          (anchor) => anchor.drawn_object.uuid === drawn_object.uuid
        )?.[0]
    );

    // calculate grid and bounds
    let start_joint = joints.filter(
      (joint) =>
        joint.anchors.filter(
          (anchor) =>
            anchor.drawn_object.uuid === drawn_object.uuid &&
            anchor.point_index === 0
        )?.[0]
    )?.[0] as JointObject;

    let end_joint = joints.filter(
      (joint) =>
        joint.anchors.filter(
          (anchor) =>
            anchor.drawn_object.uuid === drawn_object.uuid &&
            anchor.point_index === 1
        )?.[0]
    )?.[0] as JointObject;

    let start_grid;
    let start_bound;
    let end_grid;
    let end_bound;

    if (start_joint) {
      let des1 = project_context.project.get(
        start_joint.anchors[0]?.drawn_object
      ) as _3DLineObject;
      let des2 = project_context.project.get(
        start_joint.anchors[1]?.drawn_object
      ) as _3DLineObject;
      let section1 = project_context.project.get(
        des1?.details[0]
      ) as CrossSection;
      let section2 = project_context.project.get(
        des2?.details[0]
      ) as CrossSection;
      let materials1 = section1?.materials
        ?.getElements()
        .map(project_context.project.get) as ThicknessMaterialObject[];
      let materials2 = section2?.materials
        ?.getElements()
        .map(project_context.project.get) as ThicknessMaterialObject[];
      start_grid = makeGrid(
        start_joint,
        des1,
        des2,
        section1,
        section2,
        materials1,
        materials2
      ).grid;
      if (start_grid) {
        start_bound =
          getGridBounds(start_grid)[
            start_joint.anchors.findIndex(
              (x) => x.drawn_object.uuid === drawn_object.uuid
            )
          ];
      }
    }

    if (end_joint) {
      let des1 = project_context.project.get(
        end_joint.anchors[0]?.drawn_object
      ) as _3DLineObject;
      let des2 = project_context.project.get(
        end_joint.anchors[1]?.drawn_object
      ) as _3DLineObject;
      let section1 = project_context.project.get(
        des1?.details[0]
      ) as CrossSection;
      let section2 = project_context.project.get(
        des2?.details[0]
      ) as CrossSection;
      let materials1 = section1?.materials
        ?.getElements()
        .map(project_context.project.get) as ThicknessMaterialObject[];
      let materials2 = section2?.materials
        ?.getElements()
        .map(project_context.project.get) as ThicknessMaterialObject[];
      end_grid = makeGrid(
        end_joint,
        des1,
        des2,
        section1,
        section2,
        materials1,
        materials2
      ).grid;
      if (end_grid) {
        end_bound =
          getGridBounds(end_grid)[
            end_joint.anchors.findIndex(
              (x) => x.drawn_object.uuid === drawn_object.uuid
            )
          ];
      }
    }

    // if (start_bound || end_bound) {
    //   console.log(start_bound);
    //   console.log(end_bound);
    //   materials.map((e, i) => {
    //     let off1 = offsetSegment(segment[0], segment[1], offsets[i][0]);
    //     let off2 = offsetSegment(segment[0], segment[1], offsets[i][1]);

    //     if (start_bound) {
    //       off1 = [joinSegment(start_bound, off1), off1[1]];
    //       off2 = [joinSegment(start_bound, off2), off2[1]];
    //     }
    //     if (end_bound) {
    //       off1 = [off1[0], joinSegment(off1, end_bound)];
    //       off2 = [off2[0], joinSegment(off2, end_bound)];
    //     }
    //     console.log(off1);
    //     console.log(off2);
    //     return null;
    //   });
    //   throw new Error("Debug");
    // }

    return {
      thickness_materials,
      uuids: cross_section.materials?.order,
      boundaries: materials.map((e, i) => {
        let off1 = offsetSegment(segment[0], segment[1], offsets[i][0]);
        let off2 = offsetSegment(segment[0], segment[1], offsets[i][1]);

        if (start_bound) {
          off1 = [joinSegment(start_bound, off1), off1[1]];
          off2 = [joinSegment(start_bound, off2), off2[1]];
        }
        if (end_bound) {
          off1 = [off1[0], joinSegment(off1, end_bound)];
          off2 = [off2[0], joinSegment(off2, end_bound)];
        }

        return off1.concat(off2.slice(1).concat(off2.slice(0, 1)));
      }),
    };
  };

  // is_scope
  const is_scope = drawn_object.uuid === project_context.scope?.uuid;
  // console.log(drawn_object.uuid, project_context.scope?.uuid);

  // is faded
  const is_faded = !is_scope && project_context.scope !== null;

  const { thickness_materials, boundaries } = getMaterialsAndBoundaries();
  // useMemo(getMaterialsAndBoundaries, [
  //   design_object,
  //   detail,
  //   selected,
  //   is_locked,
  //   disabled,
  // ]);
  // useMemo(() => {
  //   console.log("new bd");
  //   return
  // }, [design_object, detail.internalDetails, detail.boundaryDetails]);

  useEffect(() => console.log(boundaries[0]), [boundaries]);

  return (
    <Group>
      {thickness_materials.map((thickness_material, i) => (
        <MaterialObject
          key={i}
          drawn_object={drawn_object}
          boundary={boundaries[i]}
          active_sides={[true, false, true, false]}
          thickness_material={thickness_material}
          selected={selected}
          disabled={disabled || is_faded}
          onClick={console.log}
        />
      ))}
    </Group>
  );
};

export default DetailObject;
