import { Fragment, useState } from "react";
import List from "./List";

import styles from "./DropdownObject.module.scss";
import classNames from "classnames/bind";
import Division from "./Division";
import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";
import LayerRow from "./LayerRow";
import IconCommand from "./IconCommand";
import Tooltip from "./Tooltip";
import StandardSlabImg from "../imgs/standard_slab.png";
import { ReactComponent as Search } from "../svgs/Search.svg";
import Box from "./Box";
import IconSymbol from "./IconSymbol";
import useProjectContext from "../hooks/useProjectContext";
import { ColorStyle } from "../type/Layer";

const cx = classNames.bind(styles);

const DropdownObject = ({
  object_type = "InternalDetail",
  custom_dropdown_list,
  onClose = () => {},
  onClick = () => {},
}: any) => {
  const { project_context, setProjectContext } = useProjectContext();
  const [hover_idx, setHoverIdx] = useState<boolean | number>(false);

  return (
    <div className={cx("wrapper")}>
      <List fill>
        <List type="row" fill>
          <Box width={2} height={2}>
            <Search width={28} />
          </Box>
          <input
            className={cx("frame-search-input")}
            placeholder="Search"
            autoFocus
          />
        </List>
        <div className={cx("frame-divider")} />
        {custom_dropdown_list
          ? custom_dropdown_list.map((e, idx) => (
              <List
                type="row"
                key={idx}
                onMouseEnter={() => setHoverIdx(idx)}
                onMouseLeave={() => setHoverIdx(false)}
                fill
                // style={{ width: "14rem" }}
              >
                <PropertyBlock
                  type="object"
                  value={project_context.project.get(e).name}
                  onClick={() => {
                    onClick(e, idx);
                    onClose();
                    setHoverIdx(false);
                  }}
                >
                  <IconSymbol type="material" />
                </PropertyBlock>
                {hover_idx === idx && (
                  <PropertyBlock
                    type="action"
                    icon_type="edit"
                    illust="Edit asset"
                  ></PropertyBlock>
                )}
              </List>
            ))
          : Object.values(project_context.project.named_objects)
              .filter((e) => e.type === object_type)
              .map((e, idx) => (
                <List
                  type="row"
                  key={idx}
                  onMouseEnter={() => setHoverIdx(idx)}
                  onMouseLeave={() => setHoverIdx(false)}
                  fill
                  // style={{ width: "14rem" }}
                >
                  <PropertyBlock
                    type="object"
                    value={e.name}
                    onClick={() => {
                      onClick(e);
                      onClose();
                      setHoverIdx(false);
                    }}
                  >
                    {object_type === "ColorStyle" ? (
                      <div
                        className={cx("frame-color")}
                        style={{
                          backgroundColor: (e as unknown as ColorStyle).value,
                        }}
                      ></div>
                    ) : (
                      <img src={StandardSlabImg} />
                    )}
                  </PropertyBlock>
                  {hover_idx === idx && (
                    <PropertyBlock
                      type="action"
                      icon_type="edit"
                      illust="Edit asset"
                    ></PropertyBlock>
                  )}
                </List>
                // <div className={cx("frame-object")}>
                //   {e.name}
                //   {e.thickness}
                // </div>
              ))}
      </List>
    </div>
  );
};

export default DropdownObject;
