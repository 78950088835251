import * as THREE from "three";

export const _2DArrayToVector3 = (
  points: number[],
  z: number = 0
): THREE.Vector3[] => {
  let result = [];
  for (let i = 0; i < points.length; i += 2) {
    result.push(new THREE.Vector3(points[i], points[i + 1], z));
  }

  return result;
};

export const Vector3To2DArray = (points: THREE.Vector3[]): number[] => {
  let result = [];
  for (let i = 0; i < points.length; i += 1) {
    result = result.concat([points[i].x, points[i].y]);
  }
  return result;
};

export const isVector3Valid = (vector: THREE.Vector3): boolean => {
  return (
    !isNaN(vector.x) &&
    !isNaN(vector.y) &&
    !isNaN(vector.z) &&
    isFinite(vector.x) &&
    isFinite(vector.y) &&
    isFinite(vector.z)
  );
};

export const translate = (geometry: THREE.Vector3[], vector: THREE.Vector3) => {
  return geometry.map((point) => point.clone().add(vector));
};

export const scaleVector = (vector: THREE.Vector3, scale: number) => {
  return vector.multiplyScalar(scale);
};

export const unitVector = (start: THREE.Vector3, end: THREE.Vector3) => {
  const direction = new THREE.Vector3().subVectors(end, start).normalize();

  return direction;
};

export const rotateVector = (
  vector: THREE.Vector3,
  angle: number = Math.PI / 2, // Default: 90-degree rotation
  axis: "x" | "y" | "z" | THREE.Vector3 = "z",
  ccw: boolean = true
): THREE.Vector3 => {
  const quaternion = new THREE.Quaternion();
  let rotationAxis: THREE.Vector3;

  // Handle predefined axes or custom axis
  if (typeof axis === "string") {
    rotationAxis = new THREE.Vector3(
      axis === "x" ? 1 : 0,
      axis === "y" ? 1 : 0,
      axis === "z" ? 1 : 0
    );
  } else {
    rotationAxis = axis.clone().normalize(); // Normalize to ensure valid rotation
  }

  // Apply rotation direction (CCW vs CW)
  const rotationAngle = ccw ? angle : -angle;
  quaternion.setFromAxisAngle(rotationAxis, rotationAngle);

  // Apply rotation to the vector
  return vector.clone().applyQuaternion(quaternion);
};

export const offsetSegment = (
  start: THREE.Vector3,
  end: THREE.Vector3,
  dist: number
): [THREE.Vector3, THREE.Vector3] => {
  const direction = unitVector(start, end); // Get the unit vector along the segment
  const offsetDirection = rotateVector(direction, Math.PI / 2); // Rotate 90° counterclockwise

  // Scale by distance
  const offsetVector = offsetDirection.multiplyScalar(dist);

  // Offset the start and end points
  const newStart = start.clone().add(offsetVector);
  const newEnd = end.clone().add(offsetVector);

  return [newStart, newEnd];
};

export const joinSegment = (
  seg1: [THREE.Vector3, THREE.Vector3],
  seg2: [THREE.Vector3, THREE.Vector3]
): THREE.Vector3 | null => {
  const [p1, p2] = seg1;
  const [p3, p4] = seg2;

  const x1 = p1.x,
    y1 = p1.y,
    x2 = p2.x,
    y2 = p2.y;
  const x3 = p3.x,
    y3 = p3.y,
    x4 = p4.x,
    y4 = p4.y;

  const d = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

  if (Math.abs(d) < 1e-10) {
    return null; // Lines are parallel or coincident
  }

  const a = x1 * y2 - y1 * x2;
  const b = x3 * y4 - y3 * x4;

  const x = (a * (x3 - x4) - (x1 - x2) * b) / d;
  const y = (a * (y3 - y4) - (y1 - y2) * b) / d;

  return new THREE.Vector3(x, y, 0);
};

export const offsetLine = (
  line: THREE.Vector3[],
  dist: number
): THREE.Vector3[] => {
  if (line.length < 2) {
    return [];
  }

  const segments: [THREE.Vector3, THREE.Vector3][] = [];
  for (let i = 0; i < line.length - 1; i++) {
    segments.push([line[i], line[i + 1]]);
  }

  const offsetSegs = segments.map((seg) => offsetSegment(seg[0], seg[1], dist));

  let offsetPoints: THREE.Vector3[] = [];

  offsetPoints.push(offsetSegs[0][0]); // First offset point

  for (let i = 0; i < offsetSegs.length - 1; i++) {
    const seg1 = offsetSegs[i];
    const seg2 = offsetSegs[i + 1];

    const nextPoint = joinSegment(seg1, seg2);
    if (nextPoint) {
      offsetPoints.push(nextPoint);
    }
  }

  offsetPoints.push(offsetSegs[offsetSegs.length - 1][1]); // Last offset point

  return offsetPoints;
};

export const closestPoint = (
  points: THREE.Vector3[],
  target: THREE.Vector3
) => {
  return points.reduce((closest, point) => {
    const distance = point.distanceTo(target);
    if (distance < closest.distanceTo(target)) {
      return point;
    }
    return closest;
  });
};

export const getAngle = (
  seg1: [THREE.Vector3, THREE.Vector3],
  seg2: [THREE.Vector3, THREE.Vector3],
  inDegree: boolean = true
): number => {
  const dir1 = new THREE.Vector3().subVectors(seg1[1], seg1[0]).normalize();
  const dir2 = new THREE.Vector3().subVectors(seg2[1], seg2[0]).normalize();

  const angle = Math.atan2(dir2.y, dir2.x) - Math.atan2(dir1.y, dir1.x);
  const normalizedAngle = ((angle + Math.PI) % (2 * Math.PI)) - Math.PI;

  return inDegree ? THREE.MathUtils.radToDeg(normalizedAngle) : normalizedAngle;
};

export const getBoundingBoxOfPolygon = (
  polygon: THREE.Vector3[],
  local_point: THREE.Vector3 = new THREE.Vector3(0, 0, 0),
  local_unit_direction: THREE.Vector3 = new THREE.Vector3(1, 0, 0)
): {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  width: number;
  height: number;
} => {
  const normalizedDirection = local_unit_direction.clone().normalize();

  // Local x-axis (ux, uy) and its orthogonal y-axis (vx, vy)
  const ux = normalizedDirection.x;
  const uy = normalizedDirection.y;
  const vx = -uy;
  const vy = ux;

  let minX = Infinity,
    maxX = -Infinity,
    minY = Infinity,
    maxY = -Infinity;

  for (const vertex of polygon) {
    const dx = vertex.x - local_point.x;
    const dy = vertex.y - local_point.y;

    const localX = dx * ux + dy * uy;
    const localY = dx * vx + dy * vy;

    if (localX < minX) minX = localX;
    if (localX > maxX) maxX = localX;
    if (localY < minY) minY = localY;
    if (localY > maxY) maxY = localY;
  }

  return {
    minX,
    minY,
    maxX,
    maxY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

// Arc 데이터를 SVG Path로 변환
export const convertArcToSVG = ({
  u,
  v,
  radius,
  angle,
  angleStart = 0,
  x = 0,
  y = 0,
  rotation = 0,
  scaleX = 1,
  scaleY = 1,
}) => {
  // 로컬 좌표 u, v를 rotation 값에 맞게 회전
  const rotationRad = (Math.PI / 180) * rotation;

  // 회전 변환 적용
  const rotatedU = u * Math.cos(rotationRad) - v * Math.sin(rotationRad);
  const rotatedV = u * Math.sin(rotationRad) + v * Math.cos(rotationRad);

  // 각도를 라디안으로 변환 (회전 각도 적용)
  const startAngleRad = (Math.PI / 180) * (angleStart - rotation);
  const endAngleRad = (Math.PI / 180) * (angleStart + angle - rotation);

  // 시작점과 끝점 좌표에 scaleX, scaleY를 적용
  const startX = x + (rotatedU + radius * Math.cos(startAngleRad)) * scaleX;
  const startY = y + (rotatedV - radius * Math.sin(startAngleRad)) * scaleY;
  const endX = x + (rotatedU + radius * Math.cos(endAngleRad)) * scaleX;
  const endY = y + (rotatedV - radius * Math.sin(endAngleRad)) * scaleY;

  // largeArcFlag 계산 (180도 초과 여부)
  const largeArcFlag = angle > 180 ? 1 : 0;

  return `M ${startX} ${startY} A ${radius * scaleX} ${
    radius * scaleY
  } 0 ${largeArcFlag} 0 ${endX} ${endY}`;
};

// Line 데이터를 SVG Path로 변환
export const convertLineToSVG = ({
  points,
  x = 0,
  y = 0,
  rotation = 0,
  scaleX = 1,
  scaleY = 1,
}) => {
  const radRotation = (Math.PI / 180) * rotation;
  // 시작점과 끝점에 scaleX, scaleY, rotation 적용
  const [startX, startY, ...rest] = points;
  const transformedStartX =
    x +
    (startX * Math.cos(radRotation) - startY * Math.sin(radRotation)) * scaleX;
  const transformedStartY =
    y +
    (startX * Math.sin(radRotation) + startY * Math.cos(radRotation)) * scaleY;

  const lineSegments = [];
  for (let i = 0; i < rest.length; i += 2) {
    const px = rest[i];
    const py = rest[i + 1];
    const transformedX =
      x + (px * Math.cos(radRotation) - py * Math.sin(radRotation)) * scaleX;
    const transformedY =
      y + (px * Math.sin(radRotation) + py * Math.cos(radRotation)) * scaleY;
    lineSegments.push(`L ${transformedX} ${transformedY}`);
  }

  return `M ${transformedStartX} ${transformedStartY} ${lineSegments.join(
    " "
  )}`;
};

/**
 * 여러 점(x1,y1, x2,y2, ..., xN,yN)으로 이루어진 폴리라인을
 * 두께(thickness)를 고려해 bounding box를 확장한 뒤,
 * 사각형 격자(width, height) 단위로 평행이동 복제본을 생성.
 *
 * 결과: 각 (i,j) 타일에 대응하는 "shifted polyline"을
 *       하나씩 반환 (즉, 여러 개가 생김).
 *
 * @param {object} item
 *   - { points: number[], thickness?: number }
 *     예: points = [x1, y1, x2, y2, x3, y3, ...]
 * @param {number} width   - 타일 폭
 * @param {number} height  - 타일 높이
 * @returns {Array<Array<number>>}
 *   - 각 원소는 "shiftedPoints": [sx1, sy1, sx2, sy2, ...].
 *     즉, (i, j) 타일에 대한 평행이동 후의 좌표 집합
 */
export function tileLineByWrap(item, width, height, thickness = 1) {
  const { points } = item;
  if (!points || points.length < 4) {
    // 점이 2개 미만이면 선이 안 되므로 빈 배열 리턴
    return [];
  }

  // 1) 폴리라인 전체의 min/max x,y 계산
  let minX = Infinity,
    maxX = -Infinity;
  let minY = Infinity,
    maxY = -Infinity;

  for (let i = 0; i < points.length; i += 2) {
    const x = points[i];
    const y = points[i + 1];
    if (x < minX) minX = x;
    if (x > maxX) maxX = x;
    if (y < minY) minY = y;
    if (y > maxY) maxY = y;
  }

  // 2) 두께(thickness) 반영해서 bounding box 확장
  const halfT = thickness / 2;
  minX -= halfT;
  maxX += halfT;
  minY -= halfT;
  maxY += halfT;

  // 3) 걸칠 수 있는 타일 범위
  //    floor() 결과가 음수일 수도 있으니 그대로 사용
  const iStart = Math.floor(minX / width);
  const iEnd = Math.floor(maxX / width);
  const jStart = Math.floor(minY / height);
  const jEnd = Math.floor(maxY / height);

  const shiftedPolylines = [];

  // 4) 각 (i, j) 타일마다 평행이동한 복제본 생성
  for (let i = iStart; i <= iEnd; i++) {
    for (let j = jStart; j <= jEnd; j++) {
      // 평행 이동 벡터: (-i*width, -j*height)
      const shiftedPoints = [];
      for (let idx = 0; idx < points.length; idx += 2) {
        const x = points[idx];
        const y = points[idx + 1];
        // 이동
        const sx = x - i * width;
        const sy = y - j * height;
        shiftedPoints.push(sx, sy);
      }
      shiftedPolylines.push(shiftedPoints);
    }
  }

  return shiftedPolylines;
}

/**
 * 원호(Arc): 중심 (u, v), 반지름 radius,
 * 두께 thickness, 시작각 angleStart, 총 회전각 angle.
 * bounding box (u-r, v-r) ~ (u+r, v+r)에
 * thickness를 고려해 확장 -> 각 타일에 대해 복제
 */
export function tileArcByWrap(item, width, height, thickness = 1) {
  const { u, v, radius, angle, angleStart } = item;

  // 원호의 bounding box = (u - r, v - r) ~ (u + r, v + r)
  // 두께가 있다면, stroke가 바깥쪽/안쪽으로 thickness/2씩 퍼진다고 보면
  const halfT = thickness / 2;
  const minX = u - radius - halfT;
  const maxX = u + radius + halfT;
  const minY = v - radius - halfT;
  const maxY = v + radius + halfT;

  const iStart = Math.floor(minX / width);
  const iEnd = Math.floor(maxX / width);
  const jStart = Math.floor(minY / height);
  const jEnd = Math.floor(maxY / height);

  const arcs = [];

  for (let i = iStart; i <= iEnd; i++) {
    for (let j = jStart; j <= jEnd; j++) {
      arcs.push({
        type: "Arc",
        // 평행 이동된 중심
        u: u - i * width,
        v: v - j * height,
        radius,
        angle,
        angleStart,
        thickness,
      });
    }
  }

  return arcs;
}

// Line과 Arc를 SVG Path로 결합하는 함수
export const convertToSVGPath = (data, width, height, thickness = 1) => {
  return data
    .map((item) => {
      if (item.type === "Line") {
        return tileLineByWrap(item, width, height, thickness)
          .map((points) => convertLineToSVG({ points }))
          .join(" ");
      } else if (item.type === "Arc") {
        return tileArcByWrap(item, width, height, thickness)
          .map((arcs) => convertArcToSVG(arcs))
          .join(" ");
      }
      return "";
    })
    .join(" ");
};

export const getXYCrossing = (
  point: THREE.Vector3,
  direction: THREE.Vector3
): THREE.Vector3 | null => {
  const px = point.x;
  const py = point.y;
  const dx = direction.x;
  const dy = direction.y;

  let xCrossing: THREE.Vector3 | null = null;
  let yCrossing: THREE.Vector3 | null = null;

  // x-axis (y = 0) intersection
  if (dy !== 0) {
    const tX = -py / dy;
    const x = px + tX * dx;
    xCrossing = new THREE.Vector3(x, 0, 0);
  }

  // y-axis (x = 0) intersection
  if (dx !== 0) {
    const tY = -px / dx;
    const y = py + tY * dy;
    yCrossing = new THREE.Vector3(0, y, 0);
  }

  // Return the one closer to the original point
  if (xCrossing && yCrossing) {
    return point.distanceTo(xCrossing) < point.distanceTo(yCrossing)
      ? xCrossing
      : yCrossing;
  }

  return xCrossing || yCrossing;
};
