import React, {
  useRef,
  useState,
  useEffect,
  ReactNode,
  CSSProperties,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import classNames from "classnames/bind";
import styles from "./DraggableList.module.scss";

const cx = classNames.bind(styles);

interface DraggableListProps {
  children: ReactNode;
  onChange?: (newOrder: number[]) => void;
}

const DraggableList: React.FC<DraggableListProps> = ({
  children,
  onChange = console.log,
}) => {
  const childrenArray = useMemo(
    () => React.Children.toArray(children) as React.ReactElement[],
    [children]
  );

  const [items, setItems] = useState<
    { id: number; element: React.ReactElement }[]
  >([]);

  useEffect(() => {
    // Generate new items array based on the new children
    const newItems = childrenArray.map((child, idx) => {
      const id = idx;
      return { id, element: child };
    });

    setItems(newItems);
  }, [childrenArray]);

  // Rest of your state variables and logic
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [draggingItemStyle, setDraggingItemStyle] =
    useState<CSSProperties | null>(null);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const isDragging = useRef<boolean>(false);
  const startMousePos = useRef<{ x: number; y: number } | null>(null);
  const itemsReordered = useRef<boolean>(false);
  const DRAG_THRESHOLD = 5;

  useEffect(() => {
    if (isDragging.current && draggingIndex !== null) {
      document.body.style.userSelect = "none";
      document.body.style.pointerEvents = "none";
    } else {
      document.body.style.userSelect = "auto";
      document.body.style.pointerEvents = "auto";
    }

    return () => {
      document.body.style.userSelect = "auto";
      document.body.style.pointerEvents = "auto";
    };
  }, [draggingIndex]);

  const handleMouseDown = (index: number, event: React.MouseEvent) => {
    event.preventDefault();
    startMousePos.current = { x: event.clientX, y: event.clientY };
    setDraggingIndex(index);
  };

  const startDragging = (index: number, event: React.MouseEvent) => {
    const rect = itemRefs.current[index]?.getBoundingClientRect();
    setDragOffset(event.clientY - (rect?.top || 0));

    if (rect) {
      setDraggingItemStyle({
        position: "fixed",
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        zIndex: 9999,
        pointerEvents: "none",
      });
    }

    isDragging.current = true;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging.current && draggingIndex !== null) {
      if (startMousePos.current) {
        const deltaX = Math.abs(event.clientX - startMousePos.current.x);
        const deltaY = Math.abs(event.clientY - startMousePos.current.y);

        if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
          startDragging(draggingIndex, event as unknown as React.MouseEvent);
        }
      }
    }

    if (isDragging.current && draggingIndex !== null) {
      const currentY = event.clientY - dragOffset;

      setDraggingItemStyle((prevStyle) =>
        prevStyle
          ? {
              ...prevStyle,
              top: currentY,
            }
          : null
      );

      const hoverIndex = getHoverIndex(currentY);

      if (
        hoverIndex !== draggingIndex &&
        hoverIndex >= 0 &&
        hoverIndex < items.length
      ) {
        const updatedItems = [...items];
        const [draggedItem] = updatedItems.splice(draggingIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);

        setDraggingIndex(hoverIndex);
        setItems(updatedItems);

        itemsReordered.current = true;
      }
    }
  };

  const getHoverIndex = (currentY: number): number => {
    for (let i = 0; i < itemRefs.current.length; i++) {
      const item = itemRefs.current[i];
      const rect = item?.getBoundingClientRect();

      if (rect && currentY > rect.top && currentY < rect.bottom) {
        return i;
      }
    }
    return draggingIndex!;
  };

  const handleMouseUp = () => {
    startMousePos.current = null;

    if (isDragging.current) {
      setDraggingIndex(null);
      setDraggingItemStyle(null);
      isDragging.current = false;

      if (itemsReordered.current && onChange) {
        const newOrder = items.map((item) => item.id);
        onChange(newOrder);
      }

      itemsReordered.current = false;
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draggingIndex, dragOffset, items]);

  return (
    <div className={cx("draggable-list")} style={{ position: "relative" }}>
      {items.map((item, index) => (
        <div
          key={item.id}
          ref={(el) => (itemRefs.current[index] = el)}
          onMouseDown={(e) => handleMouseDown(index, e)}
          className={cx("draggable-item")}
          style={{
            visibility:
              isDragging.current && draggingIndex === index
                ? "hidden"
                : "visible",
          }}
        >
          {item.element}
        </div>
      ))}

      {draggingItemStyle &&
        draggingIndex !== null &&
        ReactDOM.createPortal(
          <div className={cx("dragging-item")} style={draggingItemStyle}>
            {items[draggingIndex].element}
          </div>,
          document.body
        )}
    </div>
  );
};

export default DraggableList;
