import {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useReducer,
} from "react";
import * as THREE from "three";
import { Abstract, DesignatorObject } from "../type/DesignatorObject";
import { DrawnObject } from "../type/Project";
import { IntersectionDetail } from "../type/Detail";

export interface CanvasContextStateProps {
  editPoint: EditPoint | null;
  jointObjects: { [uuid: string]: JointObject };
  width: number;
  height: number;
  scale: number;
  stage: any;
  camera: any;
  baseLinePoints: THREE.Vector3[];
}

export interface CanvasContextActionProps {
  editPoint?: EditPoint | null;
  jointObjects?: { [uuid: string]: JointObject };
  width?: number;
  height?: number;
  scale?: number;
  stage?: any;
  camera?: any;
  baseLinePoints?: THREE.Vector3[];
}

export interface CanvasContextProps {
  canvasContext: CanvasContextStateProps;
  setCanvasContext: Dispatch<CanvasContextActionProps>;
}

export const CanvasContext = createContext<CanvasContextProps>(null);

const reduceCanvasContext: Reducer<
  CanvasContextStateProps,
  CanvasContextActionProps
> = (state: CanvasContextStateProps, action: CanvasContextActionProps) => {
  return { ...state, ...action };
};

export interface JointObject extends DesignatorObject {
  type: "JointObject";
  detail?: Abstract<IntersectionDetail>;
  anchors: {
    drawn_object: Abstract<DrawnObject>;
    point_index: number; // index of the point of Design Object where joint is located
  }[];
}

export interface EditPoint {
  drawn_object: Abstract<DrawnObject>;
  point_index: number;
  original_coordinates: THREE.Vector3;
  current_coordinates: number[];
}

export const CanvasContextProvider = ({ children }) => {
  const [canvasContext, setCanvasContext] = useReducer<
    Reducer<CanvasContextStateProps, CanvasContextActionProps>
  >(reduceCanvasContext, {
    editPoint: null,
    jointObjects: {},
    width: 1,
    height: 1,
    scale: 1,
    stage: null,
    camera: null,
    baseLinePoints: [],
  });

  return (
    <CanvasContext.Provider
      value={{
        canvasContext,
        setCanvasContext,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

const useCanvasContext = () => {
  return useContext(CanvasContext);
};

export default useCanvasContext;
