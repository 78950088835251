import { v4 as uuid } from "uuid";
import { UUID } from "./DesignatorObject";

export class AbstractArray<T> {
  readonly elements: { [uuid: UUID]: { uuid: UUID; data: T } };
  readonly order: UUID[];
  constructor(data_list: T[], uuid_list?: UUID[], order_idx?: number[]) {
    const uuids = uuid_list ?? data_list.map(() => uuid());
    this.elements = {
      ...Object.fromEntries(
        data_list.map((v, i) => [uuids[i], { uuid: uuids[i], data: v }])
      ),
    };
    this.order = order_idx ? order_idx.map((i) => uuids[i]) : [...uuids];
  }
  getElements = (): T[] => {
    return this.order.map((uuid) => this.elements[uuid].data);
  };
  getElementByIndex = (idx: number): T => {
    return this.elements[this.order[idx]].data;
  };
  reOrder = (new_order_idx: number[]) => {
    return new AbstractArray(this.getElements(), this.order, new_order_idx);
  };
  addElement = (new_data: T, idx?: number) => {
    const new_uuid = uuid();
    return new AbstractArray(
      this.getElements().concat(new_data),
      idx
        ? [...this.order.slice(0, idx), new_uuid, ...this.order.slice(idx)]
        : this.order.concat(new_uuid)
    );
  };
  updateElement = (uuid: UUID, new_data: T) => {
    return new AbstractArray(
      this.order.map((u) => (u === uuid ? new_data : this.elements[u].data)),
      this.order
    );
  };
  updateElementByIndex = (idx: number, new_data: T) => {
    return new AbstractArray(
      this.order.map((u, i) => (i === idx ? new_data : this.elements[u].data)),
      this.order
    );
  };
  deleteElementPermenantly = (uuid: UUID) => {
    return new AbstractArray(
      this.order.filter((u) => u !== uuid).map((u) => this.elements[u].data),
      this.order.filter((u) => u !== uuid)
    );
  };
  deleteElement = (uuid: UUID) => {
    return new AbstractArray(
      this.getElements(),
      this.order.filter((u) => u !== uuid)
    );
  };
  deleteElementByIndex = (idx: number) => {
    return new AbstractArray(
      this.getElements(),
      this.order.filter((u, i) => i !== idx)
    );
  };
  deleteElementPermenantlyByIndex = (idx: number) => {
    return new AbstractArray(
      this.order
        .filter((u, i) => i !== idx)
        .filter((u) => this.elements[u].data),
      this.order.filter((u, i) => i !== idx)
    );
  };
}
