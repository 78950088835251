import { useEffect, useMemo, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, OrthographicCamera } from "@react-three/drei";
import { OrthographicCamera as OrthographicCameraType } from "three";
import { v4 as uuid } from "uuid";
import {
  EditingObject,
  CanvasDrawnObject,
} from "../design_objects/DesignObject";
import useCanvasContext from "./CanvasContext";
import { getSnappedPoint } from "../utils/CanvasUtils";
import BoundaryDetailGrid from "../design_objects/BoundaryDetailGrid";
import * as THREE from "three";
import useMeasure from "react-use-measure";
import useProjectContext, {
  ProjectContextStateProps,
} from "../hooks/useProjectContext";
import { _3DLineObject } from "../type/GeometryObject";
import { _2DArrayToVector3 } from "../utils/GeomUtils";
import { DrawnObject, ProjectClass } from "../type/Project";
import { isObjectOfType } from "../type/DesignatorObject";
import { SectionJoin } from "../type/Detail";

export const SelectedDesignObject = (
  project_context: ProjectContextStateProps
) => {
  const selected = project_context.current_selection.filter(
    (obj) => project_context.project.drawn_objects[obj.uuid] !== null
  );
  return selected.length > 0 ? selected[0] : null;
};

const CanvasWindow = ({ width, height, use_stats = true }) => {
  const { project_context, setProjectContext } = useProjectContext();
  const { canvasContext, setCanvasContext } = useCanvasContext();
  const stageRef = useRef(null);
  const [currentDrawing, setCurrentDrawing] = useState<THREE.Vector3[]>([]);
  const cameraRef = useRef<OrthographicCameraType>(null);
  const [ref, bounds] = useMeasure();
  const [isDragging, setIsDragging] = useState(false);
  const statsRef = useRef(null);

  useEffect(() => {
    if (cameraRef.current) {
      const direction = new THREE.Vector3();
      cameraRef.current.getWorldDirection(direction);
      console.log("Camera Direction:", direction);
    }
  }, []);

  // useEffect(() => {
  //   if (use_stats) {
  //     const stats = new Stats();
  //     stats.showPanel(0); // 0: FPS, 1: 메모리 사용량, 2: CPU (JavaScript 성능)

  //     statsRef.current.appendChild(stats.dom);

  //     const animate = () => {
  //       stats.begin();
  //       // 측정할 애니메이션이나 렌더링 로직이 있으면 여기에 넣습니다
  //       stats.end();
  //       requestAnimationFrame(animate);
  //     };

  //     requestAnimationFrame(animate);

  //     return () => {
  //       // 컴포넌트가 언마운트될 때 애니메이션 중단
  //       cancelAnimationFrame(animate as any);
  //     };
  //   }
  // }, [use_stats]);

  useEffect(() => {
    if (stageRef.current) {
      setCanvasContext({ stage: stageRef.current });
    }
  }, [stageRef.current]);

  const layerRef = useRef(null);

  // useEffect(() => {
  //   if (layerRef.current) {
  //     // WebGL 컨텍스트 가져오기
  //     const gl = layerRef.current.getContext("webgl");

  //     if (!gl) {
  //       console.error("WebGL not supported");
  //       return;
  //     }

  //     // WebGL 초기화 (간단한 설정)
  //     gl.clearColor(0.0, 0.0, 0.0, 1.0); // 검은 배경
  //     gl.clear(gl.COLOR_BUFFER_BIT); // 배경 초기화

  //     // 여기서 WebGL을 사용한 추가 그래픽 작업 수행 가능
  //   }
  // }, [layerRef]);

  const updateDesignObjectData = useMemo(
    () => (newBaseLine: THREE.Vector3[]) => {
      if (newBaseLine.length > 1) {
        const new_line: _3DLineObject = {
          uuid: uuid(),
          type: "_3DLineObject",
          layer: project_context.project.layers.current,
          geometry: {
            type: "_3DLineGeometry",
            points: newBaseLine,
          },
          additional_params: {},
          details: [],
          // editable: true,
        };

        project_context.project.add(new_line);
      }
    },
    [project_context.project]
  );

  // Handle mode changes
  useEffect(() => {
    if (project_context.mode === "base") {
      setCurrentDrawing([]);
    }
    if (project_context.mode !== "edit") {
      setProjectContext({ current_selection: [] });
    }
  }, [project_context.mode]);

  // Update baseline points and joints
  useEffect(() => {
    const jointMap = new Map();
    const addJoint = (x: number, y: number, val: any) => {
      if (!jointMap.has(x)) {
        jointMap.set(x, new Map());
      }
      if (!jointMap.get(x).has(y)) {
        jointMap.get(x).set(y, []);
      }
      jointMap.get(x).get(y).push(val);
    };

    const getAllJoints = (jointMap) => {
      const allPoints = [];
      const allJoints: { [uuid: string]: any } = {};
      for (let [x, yMap] of jointMap) {
        for (let [y, anchors] of yMap) {
          allPoints.push(new THREE.Vector3(x, y, 0));
          if (anchors.length > 1) {
            const new_uuid = uuid();

            // add first joint detail
            const detail = project_context.project.makeRef<SectionJoin>(
              project_context.project.getNamedObjectsByTypeOf<SectionJoin>(
                "SectionJoin"
              )?.[0]
            );

            allJoints[new_uuid] = {
              uuid: new_uuid,
              type: "JointObject",
              detail,
              anchors,
            };
          }
        }
      }
      return [allPoints, allJoints] as const;
    };

    for (const line of Object.values(
      project_context.project.drawn_objects
    ).filter((obj) =>
      isObjectOfType<_3DLineObject>(obj, "_3DLineObject")
    ) as _3DLineObject[]) {
      for (let i = 0; i < line.geometry.points.length; i++) {
        if (
          line.uuid !== SelectedDesignObject(project_context)?.uuid ||
          i !== canvasContext.editPoint?.point_index
        ) {
          const point = line.geometry.points[i];
          addJoint(point.x, point.y, {
            drawn_object: project_context.project.makeFixedRef(line),
            point_index: i,
          });
        }
      }
    }

    const [points, joints] = getAllJoints(jointMap);
    setCanvasContext({ jointObjects: joints, baseLinePoints: points });
  }, [project_context.project.drawn_objects]);

  // Click handler
  const handleStageClick = useMemo(
    () => (e: any) => {
      if (isDragging) {
        return;
      }

      const pos = getSnappedPoint(
        e,
        cameraRef.current,
        canvasContext.baseLinePoints,
        canvasContext.scale
      );
      if (project_context.mode === "polyline") {
        if (e.button === 0) {
          if (currentDrawing.length > 1) {
            updateDesignObjectData(
              currentDrawing.slice(0, currentDrawing.length - 1).concat(pos)
            );
            setProjectContext({ mode: "base", step: 0 });
            setCurrentDrawing([]);
          } else {
            setProjectContext({ step: project_context.step + 1 });
            setCurrentDrawing([
              pos,
              new THREE.Vector3(pos.x + 10 / canvasContext.scale, pos.y, pos.z),
            ]);
          }
        } else if (e.button === 2) {
          setCurrentDrawing([]);
        }
      } else if (project_context.mode === "edit" && canvasContext.editPoint) {
        const selected_object = SelectedDesignObject(project_context);

        if (
          selected_object !== null &&
          isObjectOfType<_3DLineObject>(selected_object, "_3DLineObject")
        ) {
          const new_geometry = structuredClone(selected_object.geometry);
          new_geometry.points[canvasContext.editPoint.point_index] =
            new THREE.Vector3(pos[0], pos[1], 0);

          project_context.project.update<DrawnObject>(selected_object, {
            geometry: new_geometry,
          });

          setProjectContext({ current_selection: [] });
          setCanvasContext({ editPoint: null });
        }
      } else if (project_context.current_selection.length > 0) {
        if (e.eventObject != null && !e.drag) {
          setProjectContext({ current_selection: [] });
        }
      }
    },
    [project_context, updateDesignObjectData]
  );

  const handleMissClick = (e) => {
    if (
      e.type === "click" &&
      project_context.scope == null &&
      project_context.mode == "base"
    ) {
      setProjectContext({
        mode: "base",
        step: 0,
        scope: null,
      });
    }
    if (e.type === "dblclick" && project_context.scope != null) {
      setProjectContext({
        mode: "base",
        step: 0,
        scope: null,
      });
    }
  };

  // Mouse move handler
  const handleMouseMove = (e: any) => {
    if (e.buttons === 1) {
      setIsDragging(true); // 드래그 중으로 설정
    }
    if (project_context.mode === "polyline") {
      const pos = getSnappedPoint(
        e,
        cameraRef.current,
        canvasContext.baseLinePoints,
        canvasContext.scale
      );
      if (currentDrawing.length > 1) {
        setCurrentDrawing(
          currentDrawing.slice(0, currentDrawing.length - 1).concat([pos])
        );
      } else {
        setCurrentDrawing([pos]);
      }
    }

    if (project_context.mode === "edit" && canvasContext.editPoint) {
      const line = project_context.project.get(
        canvasContext.editPoint.drawn_object
      ) as _3DLineObject;

      if (line) {
        const pos = getSnappedPoint(
          // stageRef.current,
          e,
          cameraRef.current,
          canvasContext.baseLinePoints,
          canvasContext.scale
        );
        let point_index = canvasContext.editPoint.point_index;

        let new_line = line.geometry.points
          .slice(0, point_index)
          .concat(new THREE.Vector3(pos[0], pos[1], 0))
          .concat(line.geometry.points.slice(point_index + 1));
        setCurrentDrawing(new_line);
      }
    }
  };

  // Pan handler
  const handleMouseDown = (e: any) => {
    setIsDragging(false);
  };

  return (
    <Canvas
      ref={ref}
      orthographic
      gl={{
        toneMapping: THREE.NoToneMapping, // Ensures no tone mapping adjustments
        antialias: true,
      }}
      onClick={handleStageClick}
      onPointerMove={handleMouseMove}
      onPointerDown={handleMouseDown}
      onPointerMissed={handleMissClick}
      style={{ position: "absolute" }}
    >
      <OrthographicCamera
        ref={cameraRef}
        makeDefault
        manual
        position={[0, 0, 10]}
        zoom={1}
        left={-bounds.width / 2}
        right={bounds.width / 2}
        top={bounds.height / 2}
        bottom={-bounds.height / 2}
        onUpdate={(camera) => {
          setCanvasContext({ camera: camera });
        }}
      />

      <OrbitControls
        enableRotate={false}
        enableZoom={true}
        enableDamping={false}
        enablePan={true}
        zoomSpeed={3}
        onChange={(e) => {
          if (cameraRef.current) {
            setCanvasContext({
              scale: cameraRef.current.zoom,
            });
          }
        }}
        camera={cameraRef.current}
        zoomToCursor
      />

      <color
        attach="background"
        args={[
          project_context.project.global_setting.background_color || "white",
        ]}
      />
      {Object.values(project_context.project.drawn_objects).map(
        (drawn_object, idx) => (
          <CanvasDrawnObject
            key={idx}
            drawn_object={drawn_object as DrawnObject}
          />
        )
      )}
      {Object.values(canvasContext.jointObjects).map((joint, idx) => (
        <BoundaryDetailGrid key={idx} joint={joint} />
      ))}
      <EditingObject
        current_points={currentDrawing}
        is_scope={false}
        editable={false}
      />
    </Canvas>
  );
};

export default CanvasWindow;
