import { useState, useMemo } from "react";
import List from "./List";
import PropertyBlock from "./PropertyBlock";
import PropertyRow from "./PropertyRow";
import styles from "./LayerRow.module.scss";
import classNames from "classnames/bind";
import { Layer } from "../type/Layer";
import { Abstract, NamedObject } from "../type/DesignatorObject";
import useProjectContext from "../hooks/useProjectContext";
const cx = classNames.bind(styles);
const LayerRow = ({
  layer,
  onChange = () => {},
  onClick = () => {},
  current,
  clicked,
}: {
  layer: Abstract<Layer>;
  onChange;
  onClick;
  current;
  clicked;
}) => {
  const { project_context, setProjectContext } = useProjectContext();
  const [hover, setHover] = useState(false);
  const layer_data = useMemo(
    () => project_context.project.get(layer) as NamedObject<Layer>,
    [project_context.project, layer]
  );

  // const [clicked, setClicked] = useState(false);
  return (
    <div
      className={cx("wrapper", clicked && "clicked")}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        onClick();
        setHover(true);
      }}
    >
      <div className={cx("frame-selection", clicked && "clicked")}></div>
      <PropertyRow>
        <PropertyBlock
          type="object-input"
          placeholder={layer_data.name}
          disabled={
            (!clicked || !hover) && (!layer_data.activated || layer_data.locked)
          }
          inactivated={!clicked}
          onChange={onChange}
        >
          <div
            className={cx("frame-color")}
            style={{
              backgroundColor: project_context.project.get(layer_data.color)
                .value,
            }}
          />
        </PropertyBlock>
        <PropertyBlock
          type="action"
          icon_type={layer_data.activated ? "shown" : "hidden"}
          illust={layer_data.activated ? "Shown" : "Hidden"}
          disabled={(!hover && !layer_data.activated) || current}
          hide={(!hover && layer_data.activated) || current}
          onClick={() => {
            project_context.project.update(layer, {
              activated: !layer_data.activated,
            });
          }}
        />
        <PropertyBlock
          type="action"
          icon_type={layer_data.locked ? "locked" : "unlocked"}
          illust={layer_data.locked ? "Locked" : "Unlocked"}
          disabled={(!hover && layer_data.locked) || current}
          hide={(!hover && !layer_data.locked) || current}
          onClick={() => {
            project_context.project.update(layer, {
              locked: !layer_data.locked,
            });
          }}
        />
        <PropertyBlock
          type="action"
          icon_type={current ? "checked" : "unchecked"}
          illust={current ? null : "Check to current"}
          disabled={hover && current}
          hide={!hover && !current}
          onClick={() => {
            project_context.project.update(layer, {
              locked: false,
              activated: true,
            });
            project_context.project.setLayer(layer);
            // setDrawContext({
            //   layers: {
            //     all: draw_context.layers.all,
            //     current: layer,
            //   },
            // });
          }}
        />
      </PropertyRow>
    </div>
  );
};

export default LayerRow;
