import { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import styles from "./PropertyRowObject.module.scss";
import classNames from "classnames/bind";

import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";

import Dropdown from "./Dropdown";
import IconSymbol from "./IconSymbol";
import { ReactComponent as TempBaseline } from "../svgs/TempBaseline.svg";
import List from "./List";
import finish from "../imgs/material_finish.png";
import DraggableList from "./DraggableList";
import { CanvasContextProvider } from "./CanvasContext";
import {
  CanvasDrawnObject,
  EditingObject,
} from "../design_objects/DesignObject";
import { getOffset } from "../utils/DetailUtils";
import {
  internalDetailStageOffset,
  internalDetailStageHeight,
  internalDetailStageWidth,
  internalDetailStageGap,
} from "../utils/CanvasConstants";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import { OrthographicCamera } from "@react-three/drei";
import { CrossSection, SectionJoin } from "../type/Detail";
import { HatchType } from "../type/Layer";
import {
  Abstract,
  isAbstractObjectOfType,
  NamedObject,
} from "../type/DesignatorObject";
import { ThicknessMaterialObject, _3DLineObject } from "../type/GeometryObject";
import useProjectContext from "../hooks/useProjectContext";
import { _2DArrayToVector3 } from "../utils/GeomUtils";
import { ObjectFlags } from "typescript";

const cx = classNames.bind(styles);

const PropertyRowObject = <T extends CrossSection | SectionJoin | HatchType>({
  object,
  children,
  illusts,
  onDelete = () => {},
  onBreak = () => {},
  onEdit = () => {},
  onClick = () => {},
  ...props
}: {
  object: Abstract<T>;
  children?: any;
  illusts?: string;
  onDelete?: (e?: any) => void;
  onBreak?: (e?: any) => void;
  onEdit?: (e?: any) => void;
  onClick?: (e?: any) => void;
}) => {
  const { project_context } = useProjectContext();
  const prj = project_context.project;
  const [hover, setHover] = useState(false);
  const [popup, setPopup] = useState<string | boolean>(false);

  const obj_thickness = useMemo(() => {
    if (isAbstractObjectOfType<CrossSection>(object, "CrossSection")) {
      return prj
        .get(object)
        .materials.getElements()
        ?.reduce((prev, curr) => prev + prj.get(curr).thickness, 0);
    }
  }, [object]);

  const name = useMemo(() => {
    return (prj.get(object) as any)?.name;
  }, [object]);

  const drawing_data = useMemo(() => {
    // internal detail
    let stage_size = [
      internalDetailStageWidth,
      Math.min(obj_thickness, internalDetailStageHeight),
    ];
    let stage_offset = internalDetailStageOffset;

    if (isAbstractObjectOfType<CrossSection>(object, "CrossSection")) {
      let object_data = prj.get(object);
      let thickness_materials = object_data.materials
        .getElements()
        .map((material) => prj.get<ThicknessMaterialObject>(material));
      let bounds = ((x) => [x[0][0], x[x.length - 1][1]])(
        getOffset(object_data, thickness_materials)
      );
      const scale =
        (stage_size[1] - 2 * internalDetailStageGap) / (bounds[1] - bounds[0]);
      const baseline = _2DArrayToVector3([
        stage_offset / scale,
        -bounds[0] + internalDetailStageGap / scale,
        stage_size[0] / scale - stage_offset / scale,
        -bounds[0] + internalDetailStageGap / scale,
      ]);
      const internal_design_object: _3DLineObject = {
        uuid: "sample",
        type: "_3DLineObject",
        layer: { type: "Layer", uuid: "admin" },
        geometry: { type: "_3DLineGeometry", points: baseline },
        additional_params: {},
        details: [object],
      };
      // console.log({ scale, baseline, internal_design_object, stage_size });
      return { scale, baseline, internal_design_object, stage_size };
    }
    return null;
  }, [object, obj_thickness]);

  return (
    <PropertyRow
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      {...props}
    >
      <PropertyBlock
        type="object-selection"
        value={name}
        overwritten={prj.isOverwritten(object)}
        illusts={illusts}
        onClick={(e) => {
          onClick(e);
          setHover(false);
        }}
        object_type={object.type}
      >
        {children}
      </PropertyBlock>

      {hover && (
        <>
          <PropertyBlock
            type="action"
            icon_type="break"
            illust="Detach asset"
            onClick={onBreak}
          />
          <PropertyBlock
            type="action"
            icon_type="edit"
            illust="Edit asset"
            onClick={() => {
              setPopup("detail");
            }}
          />
        </>
      )}
      {prj.isOverwritten(object) && !hover ? (
        <PropertyBlock type="overwritten" />
      ) : (
        <PropertyBlock
          type="action"
          icon_type="delete"
          illust="Delete"
          onClick={onDelete}
        />
      )}
      {popup === "detail" ? (
        isAbstractObjectOfType<CrossSection>(object, "CrossSection") ? (
          <Dropdown
            type="left-top"
            padding={0}
            onClose={() => setPopup(false)}
            style={{
              top: "-.5rem",
              padding: ".5rem 0",
              borderRadius: ".25rem",
              maxHeight: "calc(100vh - 2rem)",
            }}
          >
            <PropertyRow>
              <PropertyBlock type="group-title">
                Edit Cross Section
              </PropertyBlock>
              <PropertyBlock type="action" icon_type="add" illust="Add" />
              <PropertyBlock type="action" icon_type="close" illust="Close" />
            </PropertyRow>
            {prj.isOverwritten(object) && (
              <PropertyRow>
                <PropertyBlock type="overwritten" />
                <div style={{ height: "2rem", width: "100%" }}></div>
                <PropertyBlock
                  type="action"
                  icon_type="sync"
                  illust="Sync to Original Asset"
                  onClick={() => {
                    prj.sync(object);
                  }}
                />
                <PropertyBlock
                  type="action"
                  icon_type="reset"
                  illust="Reset Changes"
                  onClick={() => {
                    prj.resetNamedRef(object);
                  }}
                />
              </PropertyRow>
            )}
            <PropertyRow>
              <PropertyBlock type="long-text" name="Name" value={name} />
            </PropertyRow>
            <PropertyRow>
              <PropertyBlock type="object-input" value={obj_thickness}>
                <IconSymbol type="thickness" />
              </PropertyBlock>
              <PropertyBlock
                type="action"
                icon_type="towindow"
                illust="Edit in Window"
              />
            </PropertyRow>
            <List align="center">
              <PropertyBlock
                type="action"
                icon_type="add"
                illust="Add Material"
              />
              {/* <img src={temp_cross_section} /> */}
              {drawing_data && (
                <CanvasContextProvider>
                  <Canvas
                    gl={{
                      toneMapping: THREE.NoToneMapping, // Ensures no tone mapping adjustments
                      antialias: true,
                    }}
                  >
                    <OrthographicCamera
                      makeDefault
                      position={[0, 0, 10]}
                      zoom={1}
                      left={0}
                      right={drawing_data.stage_size[0]}
                      top={0}
                      bottom={drawing_data.stage_size[1]}
                    />

                    {/* <OrbitControls
                          enableRotate={false}
                          enableZoom={false}
                          enableDamping={false}
                          enablePan={true}
                        /> */}
                    <CanvasDrawnObject
                      drawn_object={drawing_data.internal_design_object}
                    />
                    <EditingObject
                      current_points={drawing_data.baseline}
                      is_scope={true}
                      editable={false}
                      drawn_object={drawing_data.internal_design_object}
                      scale={drawing_data.scale}
                    />
                  </Canvas>
                  {/* <Stage
                    width={drawing_data.stage_size[0]}
                    height={drawing_data.stage_size[1]}
                    scaleX={drawing_data.scale}
                    scaleY={drawing_data.scale}
                  >
                    <Layer>
                      <CanvasDesignObject
                        design_object={drawing_data.internal_design_object}
                      />
                      <EditingObject
                        current_points={drawing_data.baseline}
                        is_scope={true}
                        editable={false}
                        design_object={drawing_data.internal_design_object}
                        scale={drawing_data.scale}
                      />
                    </Layer>
                  </Stage> */}
                </CanvasContextProvider>
              )}
              <PropertyBlock
                type="action"
                icon_type="add"
                illust="Add Material"
              />
            </List>
            <PropertyRow>
              <PropertyBlock
                type="subgroup"
                overwritten={
                  "base_coordinate" in prj.getNamedRefFromFixedRef(object)
                }
              >
                Baseline Position
              </PropertyBlock>
            </PropertyRow>
            <PropertyRow>
              <TempBaseline style={{ minWidth: "4rem" }} />
              <List type="column" fill>
                <List type="row" fill>
                  <PropertyBlock
                    type="object-selection"
                    value={
                      (
                        prj.get(
                          prj
                            .get(object)
                            .materials.getElementByIndex(
                              prj.get(object).base_coordinate.material_index
                            )
                        ) as NamedObject<ThicknessMaterialObject>
                      ).name
                    }
                    custom_dropdown_list={prj.get(object).materials}
                    onClick={(_, idx) => {
                      prj.update<CrossSection>(object, {
                        base_coordinate: {
                          ...prj.get(object).base_coordinate,
                          material_index: idx,
                        },
                      });
                    }}
                  >
                    <IconSymbol type="material" />
                  </PropertyBlock>
                  <PropertyBlock
                    type="action"
                    icon_type="match"
                    illust="Match Material"
                  />
                </List>
                <List type="row" fill>
                  <PropertyBlock
                    type="only-selection"
                    value={prj.get(object).base_coordinate.type}
                    // overwritten={overwritten_data.baseCoordinates?.type}
                  >
                    <IconSymbol type="material" />
                  </PropertyBlock>
                  <PropertyBlock
                    type="object-input"
                    value={prj.get(object).base_coordinate.value}
                    onChange={(event) => {
                      prj.update<CrossSection>(object, {
                        base_coordinate: {
                          ...prj.get(object).base_coordinate,
                          value: Number(event.target.value) || 0,
                        },
                      });
                    }}
                    // overwritten={overwritten_data.baseCoordinates?.value}
                  >
                    <IconSymbol type="baseoffset" />
                  </PropertyBlock>
                </List>
              </List>
            </PropertyRow>

            <PropertyRow>
              <PropertyBlock
                type="subgroup"
                overwritten={"materials" in prj.getNamedRefFromFixedRef(object)}
              >
                Materials
              </PropertyBlock>
              {"materials" in prj.getNamedRefFromFixedRef(object) && (
                <PropertyBlock
                  type="action"
                  icon_type="reset"
                  illust="Reset Changes"
                  onClick={() => {
                    prj.resetNamedRef<CrossSection>(
                      prj.get(object),
                      "materials"
                    );
                  }}
                />
              )}
              <PropertyBlock
                type="action"
                icon_type="add"
                illust="Add Material"
                onClick={(obj) => {
                  prj.update<CrossSection>(object, {
                    materials: prj
                      .get(object)
                      .materials.addElement(
                        prj.makeRef(
                          Object.values(
                            prj.getNamedObjectsByTypeOf<ThicknessMaterialObject>(
                              "ThicknessMaterialObject"
                            )
                          )[0]
                        )
                      ),
                  });
                }}
              />
            </PropertyRow>
            <DraggableList
              onChange={(reordered_idxs) => {
                const base_idx = prj.get(object).base_coordinate.material_index;
                const base_idx_re = reordered_idxs.findIndex(
                  (r_i) => r_i == base_idx
                );
                console.log(base_idx, base_idx_re);
                prj.update<CrossSection>(object, {
                  ...(base_idx === base_idx_re
                    ? {}
                    : {
                        base_coordinate: {
                          ...prj.get(object).base_coordinate,
                          material_index: base_idx_re,
                        },
                      }),
                  materials: prj.get(object).materials.reOrder(reordered_idxs),
                });
              }}
            >
              {prj
                .get(object)
                .materials.getElements()
                .map((mat, idx) => (
                  <PropertyRow key={idx}>
                    <PropertyBlock
                      type="object-selection"
                      value={
                        (prj.get(mat) as NamedObject<ThicknessMaterialObject>)
                          .name
                      }
                      object_type={mat.type}
                      onClick={(obj) => {
                        prj.update<CrossSection>(object, {
                          materials: prj
                            .get(object)
                            .materials.updateElementByIndex(idx, obj),
                        });
                      }}
                    >
                      <img src={finish} />
                    </PropertyBlock>
                    {prj.get(mat).variable && (
                      <PropertyBlock
                        type="object-input"
                        value={prj.get(mat).thickness}
                        style={{ width: "8rem" }}
                      >
                        <IconSymbol type="thickness" />
                      </PropertyBlock>
                    )}
                    <PropertyBlock
                      type="action"
                      icon_type="delete"
                      illust="Delete"
                      onClick={() => {
                        prj.update<CrossSection>(object, {
                          materials: prj
                            .get(object)
                            .materials.deleteElementByIndex(idx),
                        });
                      }}
                    />
                  </PropertyRow>
                ))}
            </DraggableList>
          </Dropdown>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </PropertyRow>
  );
};

export default PropertyRowObject;
