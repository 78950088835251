import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Stage,
  Layer,
  Rect,
  RegularPolygon,
  Group,
  Path,
  Line,
  Arc,
  Text,
  Circle,
} from "react-konva";
import { getBoundingBoxOfPolygon } from "../utils/GeomUtils";
import { ArcConfig } from "konva/lib/shapes/Arc";
import { GeometryAsSVGPath, OpenArc } from "../utils/KonvaUtils";
import useDrawContext, {
  Abstract,
  DesignatorLayer,
} from "../hooks/useDrawContext";
import useCanvasContext from "../components/CanvasContext";
import { fadedOpacity, selectedColor } from "../utils/CanvasConstants";
import Konva from "konva";

const Hatch = ({
  x,
  y,
  type,
  boundary,
  pivot_point,
  unit_direction,
  scale = 1,
  layer,
  selected,
  disabled,
  use_absolute_position,
  debug,
}: {
  x?: number;
  y?: number;
  type: string;
  boundary: number[];
  pivot_point: number[];
  unit_direction: number[];
  scale?: number;
  layer?: Abstract<DesignatorLayer>;
  window_scale?: number;
  selected?: boolean;
  disabled?: boolean;
  use_absolute_position?: boolean;
  debug?: boolean;
}) => {
  const { draw_context, getNamedObject } = useDrawContext();
  const { canvasContext } = useCanvasContext();
  const groupRef = useRef<Konva.Group>(null);

  // useEffect(() => {
  //   if (groupRef.current) {
  //     console.log(groupRef.current.getAbsolutePosition(canvasContext.stage));
  //   }
  // }, [groupRef]);
  const stroke = useMemo(() => {
    const color = getNamedObject(getNamedObject(layer)?.color);
    return {
      stroke:
        draw_context.global_setting.rendering_style === "layer"
          ? color?.value
          : color?.ctb_color,
      strokeWidth:
        draw_context.global_setting.rendering_style !== "print"
          ? 1
          : color?.ctb_lineweight
          ? color?.ctb_lineweight *
            draw_context.global_setting.scale_denominator
          : undefined,
    };
  }, [layer, draw_context.global_setting]);

  const pattern_data = useMemo(
    () =>
      type === "Ins1"
        ? {
            width: 10,
            height: 20,
            scale: 5,
            hatch_data: [
              { type: "Line", points: [1, 16, 4, 4] },
              { type: "Line", points: [9, 16, 6, 4] },
              {
                type: "Arc",
                u: 5,
                v: 16,
                radius: 4,
                angle: 180,
              },
              {
                type: "Arc",
                u: 10,
                v: 4,
                radius: 4,
                angle: 180,
                angleStart: 180,
              },
            ],
          }
        : type === "Ins2"
        ? {
            width: 8,
            height: 20,
            scale: 5,
            hatch_data: [
              { type: "Line", points: [0, 16, 4, 4] },
              { type: "Line", points: [8, 16, 4, 4] },
              {
                type: "Arc",
                u: 4,
                v: 16,
                radius: 4,
                angle: 180,
                angleStart: 180,
              },
              {
                type: "Arc",
                u: 8,
                v: 4,
                radius: 4,
                angle: 180,
              },
            ],
          }
        : type === "Diag1"
        ? {
            width: 20,
            height: 20,
            scale: 3,
            hatch_data: [
              { type: "Line", points: [0, 0, 20, 20] },
              { type: "Line", points: [10, 0, 30, 20] },
            ],
          }
        : type === "Cross1"
        ? {
            width: 20,
            height: 20,
            scale: 3,
            hatch_data: [
              { type: "Line", points: [0, 0, 0, 20] },
              { type: "Line", points: [10, 0, 10, 20] },
              { type: "Line", points: [0, 0, 20, 0] },
              { type: "Line", points: [0, 10, 20, 10] },
            ],
          }
        : type === "Pt1"
        ? {
            width: 20,
            height: 40,
            scale: 5,
            hatch_data: [
              { type: "Line", points: [0, 0, 1, 1] },
              { type: "Line", points: [5, 5, 4, 6] },
              { type: "Line", points: [1, 7, 2, 7] },
              { type: "Line", points: [14, 14, 14, 15] },
              { type: "Line", points: [11, 12, 12, 13] },
              { type: "Line", points: [7, 1, 8, 1] },
              { type: "Line", points: [4, 12, 5, 13] },
              { type: "Line", points: [3, 15, 2, 15] },
              { type: "Line", points: [0, 20, 1, 21] },
              { type: "Line", points: [10, 25, 11, 26] },
              { type: "Line", points: [1, 27, 2, 27] },
              { type: "Line", points: [13, 24, 13, 25] },
              { type: "Line", points: [11, 31, 12, 32] },
              { type: "Line", points: [7, 21, 8, 21] },
              { type: "Line", points: [3, 35, 2, 35] },
            ],
          }
        : type === "Conc1"
        ? {
            width: 600,
            height: 600,
            scale: 1,
            hatch_data: [
              { type: "Line", points: [600, 0, 0, 600] },
              { type: "Line", points: [625, 0, 25, 600] },
              { type: "Line", points: [575, 0, -25, 600] },
              {
                type: "Line",
                points: [475, 120, 510, 130, 485, 95, 475, 120],
              },
              {
                type: "Line",
                points: [475, 70, 510, 75, 485, 55, 475, 70],
              },
              {
                type: "Line",
                points: [525, 90, 540, 95],
              },
            ],
          }
        : {
            width: 20,
            height: 20,
            scale: 1,
            hatch_data: [],
          },
    [type]
  );
  const [pattern_configs, setPatternConfigs] = useState([]); // 타일 패턴 크기
  const [clipPolygon, setClipPolygon] = useState(() => (ctx) => {
    ctx.beginPath();
    ctx.closePath();
  }); // 폴리곤 변의 개수
  const scale_tot = scale * pattern_data.scale;

  useEffect(() => {
    const createClipPolygon = (ctx) => {
      ctx.beginPath();
      ctx.moveTo(boundary[0], boundary[1]);
      for (let i = 2; i < boundary.length; i += 2) {
        ctx.lineTo(boundary[i], boundary[i + 1]);
      }
      ctx.closePath();
    };

    setClipPolygon(() => createClipPolygon);
  }, [boundary]);

  const [new_bd, setNewBd] = useState([]);

  useEffect(() => {
    const config_data = [];
    const [ux, uy] = unit_direction.map(
      (e) => e / Math.sqrt(unit_direction[0] ** 2 + unit_direction[1] ** 2)
    );
    const [vx, vy] = [-uy, ux];
    // const new_pivot_point = [
    //   pivot_point[0] +
    //     // (x ?? 0) -
    //     groupRef.current.getAbsolutePosition(canvasContext.stage).x,
    //   pivot_point[1] +
    //     // (y ?? 0) -
    //     groupRef.current.getAbsolutePosition(canvasContext.stage).y,
    // ];
    const new_pivot_point = [pivot_point[0], pivot_point[1]];
    const new_bd2 = [];
    const bb_data = getBoundingBoxOfPolygon(
      boundary,
      new_pivot_point,
      unit_direction
    );
    for (
      let i = -1;
      i < 1 + bb_data.width / pattern_data.width / scale_tot;
      i++
    ) {
      for (
        let j = -1;
        j < 1 + bb_data.height / pattern_data.height / scale_tot;
        j++
      ) {
        const x =
          new_pivot_point[0] +
          (scale_tot *
            (i + Math.floor(bb_data.minX / pattern_data.width / scale_tot)) *
            pattern_data.width *
            ux +
            scale_tot *
              (j + Math.floor(bb_data.minY / pattern_data.height / scale_tot)) *
              pattern_data.height *
              vx);
        const y =
          new_pivot_point[1] +
          (scale_tot *
            (i + Math.floor(bb_data.minX / pattern_data.width / scale_tot)) *
            pattern_data.width *
            uy +
            scale_tot *
              (j + Math.floor(bb_data.minY / pattern_data.height / scale_tot)) *
              pattern_data.height *
              vy);
        const rot = (Math.atan2(uy, ux) * 180) / Math.PI;
        config_data.push({ x, y, rot });
        if (i === 0 && j === 0) {
          new_bd2.push([
            x,
            y,
            x + scale_tot * pattern_data.width * ux,
            y + scale_tot * pattern_data.width * uy,
            x +
              scale_tot * pattern_data.width * ux +
              scale_tot * pattern_data.height * vx,
            y +
              scale_tot * pattern_data.width * uy +
              scale_tot * pattern_data.height * vy,
            x + scale_tot * pattern_data.height * vx,
            y + scale_tot * pattern_data.height * vy,
          ]);
        }
      }
    }
    setPatternConfigs(config_data);
    const [p0x, p0y] = [
      new_pivot_point[0] + bb_data.minX * ux + bb_data.minY * vx,
      new_pivot_point[1] + bb_data.minX * uy + bb_data.minY * vy,
    ];
    const [p1x, p1y] = [
      p0x + (bb_data.width * ux + bb_data.height * vx),
      p0y + (bb_data.width * uy + bb_data.height * vy),
    ];
    setNewBd([
      ...new_bd2,
      [
        p0x,
        p0y,
        p0x + bb_data.width * ux,
        p0y + bb_data.width * uy,
        p1x,
        p1y,
        p0x + bb_data.height * vx,
        p0y + bb_data.height * vy,
      ],
    ]);
  }, [
    boundary,
    pivot_point,
    unit_direction[0],
    unit_direction[1],
    pattern_data.width,
    pattern_data.height,
    scale_tot,
  ]);

  const hatch_data = useMemo(
    () =>
      pattern_configs
        .map((e) =>
          pattern_data.hatch_data.map((hatch) => ({
            ...hatch,
            x: e.x,
            y: e.y,
            rotation: e.rot,
            scaleX: scale_tot,
            scaleY: scale_tot,
          }))
        )
        .reduce((prev, curr) => [...prev, ...curr], []),
    [pattern_data, pattern_configs]
  );

  return (
    <Group>
      <Group
        ref={groupRef}
        clipFunc={clipPolygon}
        x={x}
        y={y}
        opacity={disabled ? fadedOpacity : 1}
      >
        <GeometryAsSVGPath
          stroke={selected ? selectedColor : stroke.stroke ?? "black"}
          strokeWidth={
            (stroke.strokeWidth ?? 1) /
            // scale /
            (draw_context.global_setting.rendering_style === "print"
              ? 1
              : canvasContext.scale)
          }
          data={hatch_data}
        />
      </Group>
      {debug && (
        <>
          <Text x={x} y={y} text="text" />
          <Text
            x={groupRef.current?.getAbsolutePosition(canvasContext.stage).x}
            y={groupRef.current?.getAbsolutePosition(canvasContext.stage).y}
            text="group"
          />
          <Text x={pivot_point[0]} y={pivot_point[1]} text="pivot" />
          {new_bd.map((e, idx) => (
            <Line
              key={idx}
              x={x}
              y={y}
              points={e}
              stroke="red"
              strokeWidth={1}
              strokeScaleEnabled={false}
              closed
              hitStrokeWidth={0}
            />
          ))}
        </>
      )}
    </Group>
  );
};

export default Hatch;
