import { AbstractArray } from "./AbstractArray";
import {
  GeometryObject,
  _3DLineObject,
  _3DPointObject,
} from "./GeometryObject";

type PartiallyOptional<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

export type UUID = string;

export interface DesignatorObject {
  uuid: UUID;
  type: string;
  parent_uuid?: UUID;
}

export type FixedRef<T extends DesignatorObject> = {
  uuid: UUID;
  type: T["type"];
};

export type Abstract<T extends DesignatorObject> = T | FixedRef<T>;

export type NamedRef<T extends DesignatorObject> = FixedRef<T> & {
  ref_uuid: UUID;
  name: string;
} & Partial<Omit<T, "uuid" | "type">>;
const fn = (par: Abstract<_3DLineObject>) => {
  if (isObjectOfType<_3DLineObject>(par, "_3DLineObject")) {
    return par;
  }
  return par;
};

export type NamedObject<T extends DesignatorObject> = T & { name: string };

export interface Group<T extends DesignatorObject> extends DesignatorObject {
  type: string;
  objects: AbstractArray<T>;
}

// type checker

export const isObjectOfType = <T extends DesignatorObject>(
  obj: DesignatorObject,
  target_type: T["type"]
): obj is T => {
  return obj.type === target_type;
};

export const isAbstractObjectOfType = <T extends DesignatorObject>(
  obj: Abstract<DesignatorObject>, // Abstract<_3DLineObject>
  target_type: T["type"] // "_3DLineObject"
): obj is Abstract<T> => {
  return obj.type === target_type;
};

// sample type checking
const GeometryObjectTypeChecker = (par: GeometryObject) => {
  if (isObjectOfType<_3DLineObject>(par, "_3DLineObject")) {
    return par;
  } else if (isObjectOfType<_3DPointObject>(par, "_3DPointObject")) {
    return par;
  }
  return par;
};
