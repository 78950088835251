import { layerToLinestyle, leftClickOnly } from "../utils/CanvasUtils";
import Hatch from "./Hatch";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { fadedOpacity, selectedColor } from "../utils/CanvasConstants";
import { getXYCrossing } from "../utils/GeomUtils";
import { Group, Polygon, Polyline } from "../utils/Geometry";
import { DrawnObject } from "../type/Project";
import { _3DLineObject, ThicknessMaterialObject } from "../type/GeometryObject";
import useProjectContext from "../hooks/useProjectContext";
import { isObjectOfType } from "../type/DesignatorObject";
import { HatchType, Layer } from "../type/Layer";
import * as THREE from "three";

const MaterialObject = forwardRef(
  (
    {
      drawn_object,
      boundary,
      active_sides,
      thickness_material,
      selected,
      disabled,
      onClick,
    }: {
      drawn_object: DrawnObject;
      boundary: THREE.Vector3[];
      active_sides: boolean[];
      thickness_material: ThicknessMaterialObject;
      selected: boolean;
      disabled?: boolean;
      onClick?: (a: any) => any;
    },
    ref
  ) => {
    const { project_context, setProjectContext } = useProjectContext();
    const [instance_uuid, setInstanceUuid] = useState<string>();
    const material = useMemo(
      () => project_context.project.get(thickness_material.material),
      [thickness_material]
    );

    // useEffect(() => {
    //   // const new_uuid = getNewUUid();
    //   // setInstanceUuid(new_uuid);
    //   console.log("updating material uuid");

    //   let fixed_ref = project_context.project.makeRef<ThicknessMaterialObject>({
    //     name: "", // TODO
    //     ...thickness_material,
    //   });
    //   setInstanceUuid(fixed_ref.uuid);

    //   // setDrawContext((draw_context) => ({
    //   //   designObjects: {
    //   //     ...draw_context.designObjects,
    //   //     ...{
    //   //       [new_uuid]: {
    //   //         uuid: new_uuid,
    //   //         ref_uuid: material.uuid,
    //   //         material: abstractify(material),
    //   //         type: "DesignObject",
    //   //         designObjectType: "ThicknessMaterial",
    //   //         layer: layers.admin,
    //   //       },
    //   //     },
    //   //   },
    //   // }));
    // }, []);

    useImperativeHandle(
      ref,
      () => ({
        uuid: instance_uuid,
        ref_uuid: material.uuid,
        type: "ThicknessMaterial",
      }),
      [instance_uuid, material]
    );
    const curr_selected = useMemo(
      () =>
        selected ||
        project_context.current_selection.filter(
          (e) => e.uuid === instance_uuid
        ).length > 0,
      [project_context.current_selection, instance_uuid]
    );

    // check layer activated
    let design_object_layer = isObjectOfType<_3DLineObject>(
      drawn_object,
      "_3DLineObject"
    )
      ? project_context.project.get(drawn_object.layer)
      : null;

    // outline
    const outline_layer = useMemo(
      () => project_context.project.get(material.outline_layer),
      [material, project_context.project]
    );
    const linestyle = useMemo(
      () => layerToLinestyle(outline_layer, project_context.project),
      [outline_layer, project_context.project]
    );

    let closed_boundary = boundary.concat(boundary.slice(0, 1));
    let active_segments: THREE.Vector3[][] = [];

    for (let i = 0; i < active_sides.length; i++) {
      if (!active_sides[i]) {
        continue;
      }
      active_segments.push(closed_boundary.slice(i, i + 2));
    }

    const SegmentLine = ({ segment, ...props }) => {
      return (
        <Polyline
          layer={project_context.project.makeFixedRef(outline_layer)}
          points={segment}
          {...linestyle}
          {...(curr_selected ? { stroke: selectedColor } : {})}
          strokeScaleEnabled={
            project_context.project.global_setting.rendering_style === "print"
          }
          {...props}
        />
      );
    };

    // hatch
    const hatch: HatchType = project_context.project.get(material.hatch);
    const hatch_layer: Layer = project_context.project.get(
      material.hatch_layer
    );

    // useEffect(() => {
    //   const d = hatch.value;
    // }, []);

    const hatch_props = useMemo(
      () => ({
        type: hatch?.value,
        boundary,
        pivot_point: hatch?.value.includes("Ins")
          ? getXYCrossing(boundary[0], boundary[1].clone().sub(boundary[0]))
          : new THREE.Vector3(0, 0, 0),
        unit_direction: hatch?.value.includes("Ins")
          ? boundary[1].clone().sub(boundary[0])
          : new THREE.Vector3(1, 0, 0),
        scale: hatch?.value.includes("Ins")
          ? (thickness_material.thickness ?? 1) / 100
          : hatch?.scale ?? 1,
        layer: hatch_layer
          ? project_context.project.makeFixedRef(hatch_layer)
          : null,
        // debug: hatch?.value.includes("Ins"),
      }),
      [boundary, hatch, hatch_layer]
    );

    return (
      <Group
        opacity={disabled ? fadedOpacity : 1}
        onClick={leftClickOnly(() => {
          if (project_context.scope) {
            console.log(material, instance_uuid);
            setProjectContext({
              current_selection: [
                {
                  uuid: instance_uuid,
                  type: project_context.project.getObjectByUuid(instance_uuid)
                    .type,
                },
              ],
            });
          }
          // console.log(draw_context, abstract_obj);
        })}
        renderOrder={curr_selected ? 5 : 0}
        depthTest={!curr_selected}
        depthWrite={!curr_selected}
      >
        {design_object_layer.activated &&
          outline_layer.activated &&
          active_segments.map((segment, idx) => (
            <SegmentLine
              key={idx}
              segment={segment}
              renderOrder={999}
              opacity={disabled ? fadedOpacity : 1}
            />
          ))}
        {
          <Polygon
            points={boundary}
            fill={selectedColor}
            strokeWidth={0}
            opacity={curr_selected ? 0.05 : 0}
            closed={true}
            stroke={null}
            renderOrder={10}
          />
        }
        {design_object_layer.activated && hatch_layer?.activated && (
          <Hatch
            {...hatch_props}
            selected={curr_selected}
            useCopy={hatch?.value.includes("Ins")}
            opacity={disabled ? fadedOpacity : 1}
          />
        )}
      </Group>
    );
  }
);

export default MaterialObject;
