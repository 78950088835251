import _ from "lodash";
// import { getModelForClass, prop, DocumentType } from "@typegoose/typegoose";
import { Layer } from "./Layer";
import {
  Abstract,
  DesignatorObject,
  NamedObject,
  NamedRef,
  FixedRef,
  isObjectOfType,
} from "./DesignatorObject";
import {
  GeometryObject,
  GeometryObjectGroup,
  _3DLineObject,
  _3DPointObject,
} from "./GeometryObject";
import {
  ProjectContextActionProps,
  ProjectContextStateProps,
} from "../hooks/useProjectContext";
import { Dispatch } from "react";
import { v4 } from "uuid";
import {
  isNamedRef,
  isFixedRef,
  isLayer,
  isNamedObject,
} from "../utils/ObjectUtils";

export interface Project extends DesignatorObject {
  layers: {
    all: Layer[];
    current: FixedRef<Layer>;
  };
  named_objects: NamedObject<DesignatorObject>[];
  drawn_objects: {
    [uuid: string]: DrawnObject;
  };
  global_setting: GlobalSetting;
}

export type DrawnObject =
  | GeometryObject
  | GeometryObjectGroup
  | NamedRef<GeometryObject | GeometryObjectGroup>;

export interface GlobalSetting extends DesignatorObject {
  background_color: string;
  rendering_style: "print" | "layer" | "uniform";
  scale_denominator: number;
}

export class ProjectClass {
  public readonly uuid: string;

  public readonly type: "Project";

  public readonly layers: {
    all: Layer[];
    current: FixedRef<Layer>;
  };

  public readonly named_objects: {
    [uuid: string]: NamedObject<DesignatorObject>;
  };

  public readonly drawn_objects: {
    [uuid: string]: DrawnObject;
  };

  public readonly global_setting: GlobalSetting;

  public readonly setProjectContext: Dispatch<
    | ProjectContextActionProps
    | ((prev: ProjectContextStateProps) => ProjectContextActionProps)
  > | null;

  constructor(
    uuid: string,
    layers: { all: Layer[]; current: FixedRef<Layer> },
    named_objects: { [uuid: string]: NamedObject<DesignatorObject> },
    drawn_objects: { [uuid: string]: DrawnObject },
    global_setting: GlobalSetting,
    setProjectContext: Dispatch<ProjectContextActionProps> | null
  ) {
    this.uuid = uuid;
    this.type = "Project";
    this.layers = layers;
    this.named_objects = named_objects;
    this.drawn_objects = drawn_objects;
    this.global_setting = global_setting;
    this.setProjectContext = setProjectContext;
  }

  public makeFixedRef = <T extends DesignatorObject>(
    obj: Abstract<T>
  ): FixedRef<T> => {
    if (!this.get(obj) === undefined) {
      console.error(`object ${obj} does not exist in project`);
    }

    return {
      uuid: obj.uuid,
      type: obj.type,
    };
  };

  _addNamedObject = (obj: NamedObject<DesignatorObject>) => {
    this.setProjectContext((prev) => ({
      project: new ProjectClass(
        prev.project.uuid,
        prev.project.layers,
        { ...prev.project.named_objects, [obj.uuid]: obj },
        prev.project.drawn_objects,
        prev.project.global_setting,
        prev.project.setProjectContext
      ),
    }));
  };

  _addDrawnObject = (obj: DrawnObject) => {
    this.setProjectContext((prev) => ({
      project: new ProjectClass(
        prev.project.uuid,
        prev.project.layers,
        prev.project.named_objects,
        { ...prev.project.drawn_objects, [obj.uuid]: obj },
        prev.project.global_setting,
        prev.project.setProjectContext
      ),
    }));
  };

  _addLayer = (obj: Layer) => {
    this.setProjectContext((prev) => ({
      project: new ProjectClass(
        prev.project.uuid,
        {
          all: [...prev.project.layers.all, obj],
          current: prev.project.layers.current,
        },
        prev.project.named_objects,
        prev.project.drawn_objects,
        prev.project.global_setting,
        prev.project.setProjectContext
      ),
    }));
  };

  public setLayer = (obj: Abstract<Layer>) => {
    this.setProjectContext((prev) => ({
      project: new ProjectClass(
        prev.project.uuid,
        {
          all: [...prev.project.layers.all],
          current: { uuid: obj.uuid, type: obj.type },
        },
        prev.project.named_objects,
        prev.project.drawn_objects,
        prev.project.global_setting,
        prev.project.setProjectContext
      ),
    }));
  };

  public add = (obj: NamedObject<DesignatorObject> | DrawnObject | Layer) => {
    if (isLayer(obj)) {
      this._addLayer(obj);
    } else if (isNamedObject(obj)) {
      this._addNamedObject(obj);
    } else {
      this._addDrawnObject(obj);
    }
  };

  _getNamedObjectByUuid = (uuid: string) => {
    return this.named_objects[uuid];
  };

  _getDrawnObjectByUuid = <T extends DesignatorObject>(
    uuid: string
  ): T | NamedRef<T> => {
    return this.drawn_objects[uuid] as T | NamedRef<T>;
  };

  _getLayerByUuid = (uuid: string) => {
    return this.layers.all.find((obj) => obj.uuid === uuid);
  };

  public getObjectByUuid = (uuid: string) => {
    const namedObject = this._getNamedObjectByUuid(uuid);
    if (namedObject) return namedObject;

    const drawnObject = this._getDrawnObjectByUuid(uuid);
    if (drawnObject) return drawnObject;

    const layer = this._getLayerByUuid(uuid);
    if (layer) return layer;

    return null;
  };

  // default로 overriden된 오브젝트를 반환
  public getFromNamedRef = <T extends DesignatorObject>(
    obj: NamedRef<T>,
    get_original: Boolean = false
  ): T => {
    const original = this.getObjectByUuid(obj.ref_uuid) as unknown as T;
    if (!original) return null;
    if (get_original) return original;
    const cloned = _.cloneDeep(original);

    return _.merge(cloned, obj);
  };

  // fixed가 가리키는 것이 named ref인지 체크 후 named ref이면 getFromNamedRef
  public getFromFixedRef = <T extends DesignatorObject>(
    obj: FixedRef<T>
  ): T => {
    if (isLayer(obj)) {
      return this._getLayerByUuid(obj.uuid) as unknown as T;
    }
    const drawn_object = this._getDrawnObjectByUuid<T>(obj.uuid);
    if (drawn_object) {
      if (isNamedRef(drawn_object)) {
        return this.getFromNamedRef<T>(drawn_object);
      } else {
        return drawn_object;
      }
    }
    const named_obj = this._getNamedObjectByUuid(obj.uuid);
    if (named_obj) {
      return named_obj as unknown as T;
    }
    return null;
  };

  // fixed가 가리키는 것이 named ref인지 체크 후 반환
  public getNamedRefFromFixedRef = <T extends DesignatorObject>(
    obj: FixedRef<T>
  ): NamedRef<T> => {
    if (isLayer(obj)) {
      return undefined;
    }
    const drawn_object = this._getDrawnObjectByUuid<T>(obj.uuid);
    if (drawn_object) {
      if (isNamedRef(drawn_object)) {
        return drawn_object;
      }
    }
    return null;
  };

  // 원본이면 그냥 반환, fixed ref이면 getFromFixedRef
  public get = <T extends DesignatorObject>(obj: Abstract<T>): T => {
    if (obj === undefined) {
      return;
    }
    if (isFixedRef<T>(obj)) {
      return this.getFromFixedRef(obj);
    }
    return obj;
  };

  public getNamedObjectsByTypeOf = <T extends DesignatorObject>(
    type: T["type"]
  ): NamedObject<T>[] => {
    return Object.entries(this.named_objects)
      .filter(([key, value]) => this.named_objects[key].type === type)
      .map(
        ([key, value]) => this.named_objects[key] as unknown as NamedObject<T>
      );
  };

  _updateNamedObject = (
    target: FixedRef<DesignatorObject>,
    value: Partial<NamedObject<DesignatorObject>>
  ) => {
    const { uuid, type, ...to_update } = value;
    if (this.named_objects[target.uuid]) {
      this.setProjectContext((prev) => ({
        project: new ProjectClass(
          prev.project.uuid,
          prev.project.layers,
          {
            ...prev.project.named_objects,
            [target.uuid]: {
              ...prev.project.named_objects[target.uuid],
              ...to_update,
            },
          },
          prev.project.drawn_objects,
          prev.project.global_setting,
          prev.project.setProjectContext
        ),
      }));
    }
  };

  _updateDrawnObject = (
    target: FixedRef<DesignatorObject>,
    value: Partial<DrawnObject>
  ) => {
    const { uuid, type, ...to_update } = value;
    if (this.drawn_objects[target.uuid]) {
      this.setProjectContext((prev) => ({
        project: new ProjectClass(
          prev.project.uuid,
          prev.project.layers,
          prev.project.named_objects,
          {
            ...prev.project.drawn_objects,
            [target.uuid]: {
              ...prev.project.drawn_objects[target.uuid],
              ...to_update,
            },
          },
          prev.project.global_setting,
          prev.project.setProjectContext
        ),
      }));
    }
  };

  _updateLayer = (
    target: FixedRef<DesignatorObject>,
    value: Partial<Layer>
  ) => {
    const { uuid, type, ...to_update } = value;
    if (this.layers[target.uuid]) {
      this.setProjectContext((prev) => ({
        project: new ProjectClass(
          prev.project.uuid,
          {
            all: prev.project.layers.all.map((layer) => {
              if (layer.uuid === target.uuid) {
                return { ...layer, ...to_update };
              }
              return layer;
            }),
            current: prev.project.layers.current,
          },
          prev.project.named_objects,
          prev.project.drawn_objects,
          prev.project.global_setting,
          prev.project.setProjectContext
        ),
      }));
    }
  };

  public update = <T extends DesignatorObject>(
    target: FixedRef<T>,
    value: Partial<NamedObject<T>>
  ) => {
    this._updateNamedObject(target, value);
    this._updateDrawnObject(target, value);
    if (!value.type || value.type === "Layer") {
      this._updateLayer(target, value as unknown as Partial<Layer>);
    }
  };

  public sync = <T extends DesignatorObject>(obj: FixedRef<T>) => {
    const obj_data = this.get(obj);
    if (isNamedObject(obj_data)) {
      this.update({ uuid: obj.uuid, type: obj.type }, obj_data);
      this.resetNamedRef(obj);
    }
  };

  public resetNamedRef = <T extends DesignatorObject>(
    obj: FixedRef<T>,
    key?: keyof T
  ) => {
    const obj_data = this.get(obj);
    if (isNamedObject(obj_data)) {
      this.update({ uuid: obj.uuid, type: obj.type }, obj_data);
      if (this.drawn_objects[obj.uuid]) {
        this.setProjectContext((prev) => ({
          project: new ProjectClass(
            prev.project.uuid,
            prev.project.layers,
            prev.project.named_objects,
            {
              ...prev.project.drawn_objects,
              [obj.uuid]: this.getNamedRef(obj_data),
            },
            prev.project.global_setting,
            prev.project.setProjectContext
          ),
        }));
      }
    }
  };

  _deleteNamedObject = (target: FixedRef<DesignatorObject>) => {
    if (this.named_objects[target.uuid]) {
      this.setProjectContext((prev) => ({
        project: new ProjectClass(
          prev.project.uuid,
          prev.project.layers,
          _.omit(prev.project.named_objects, target.uuid),
          prev.project.drawn_objects,
          prev.project.global_setting,
          prev.project.setProjectContext
        ),
      }));
    }
  };

  _deleteDrawnObject = (target: FixedRef<DesignatorObject>) => {
    if (this.drawn_objects[target.uuid]) {
      this.setProjectContext((prev) => ({
        project: new ProjectClass(
          prev.project.uuid,
          prev.project.layers,
          prev.project.named_objects,
          _.omit(prev.project.drawn_objects, target.uuid),
          prev.project.global_setting,
          prev.project.setProjectContext
        ),
      }));
    }
  };

  _deleteLayer = (target: FixedRef<DesignatorObject>) => {
    if (this.layers[target.uuid]) {
      this.setProjectContext((prev) => ({
        project: new ProjectClass(
          prev.project.uuid,
          {
            all: prev.project.layers.all.filter(
              (layer) => layer.uuid !== target.uuid
            ),
            current:
              prev.project.layers.current.uuid === target.uuid
                ? prev.project.makeFixedRef(
                    prev.project.layers.all.filter(
                      (layer) => layer.uuid !== target.uuid
                    )[0]
                  )
                : prev.project.layers.current,
          },
          prev.project.named_objects,
          prev.project.drawn_objects,
          prev.project.global_setting,
          prev.project.setProjectContext
        ),
      }));
    }
  };

  public delete = (target: FixedRef<DesignatorObject>) => {
    this._deleteNamedObject(target);
    this._deleteDrawnObject(target);
    this._deleteLayer(target);
  };

  getNamedRef = <T extends DesignatorObject>(
    obj: NamedObject<T>
  ): NamedRef<T> => {
    const { uuid, type } = obj;
    const new_uuid: string = v4();
    const new_named_ref = {
      uuid: new_uuid,
      type,
      ref_uuid: uuid,
      name: obj.name,
    };
    return new_named_ref as NamedRef<T>;
  };

  public makeRef = <T extends DesignatorObject>(
    obj: NamedObject<T>
  ): FixedRef<T> => {
    if (obj === undefined) {
      return obj;
    }
    const new_named_ref = this.getNamedRef(obj);
    this.add(new_named_ref);
    return this.makeFixedRef<T>(new_named_ref);
  };

  public updateGlobalSetting = (global_setting: Partial<GlobalSetting>) => {
    this.setProjectContext((prev) => ({
      project: new ProjectClass(
        prev.project.uuid,
        prev.project.layers,
        prev.project.named_objects,
        prev.project.drawn_objects,
        { ...global_setting, ...prev.project.global_setting },
        prev.project.setProjectContext
      ),
    }));
  };

  public isOverwritten = <T extends DesignatorObject>(
    obj: NamedRef<T> | Abstract<T>
  ): boolean => {
    const keys = Object.keys(obj);
    if (!("ref_uuid" in obj)) {
      // FixedRef<T>
      if (keys.length === 2 && keys.includes("uuid") && keys.includes("type")) {
        return this.isOverwritten(this.get(obj));
      }
      // T
      return false;
    }
    // NamedRef<T>
    return !(
      keys.length === 4 &&
      keys.includes("uuid") &&
      keys.includes("type") &&
      keys.includes("ref_uuid") &&
      keys.includes("name")
    );
  };
}

// // Document<unknown, BeAnObject, ProjectClass> & ProjectClass & {
// //   _id: Types.ObjectId;
// // } & {
// //   __v: number;
// // } & IObjectWithTypegooseFunction

// // Document<unknown, BeAnObject, ProjectClass> & Omit<ProjectClass & {
// //   _id: Types.ObjectId;
// // } & {
// //   __v: number;
// // }, "typegooseName"> & IObjectWithTypegooseFunction

// // 예시
// const a = new ProjectClass({});
// const b: NamedRef<_3DLineObject> = {
//   uuid: "",
//   type: "_3DLineObject",
//   ref_uuid: "",
//   name: "",
// };

// const c = a.get(b);
