import { Group } from "react-konva";
import useDrawContext, {
  CrossSection,
  DesignObject,
  Detail,
  LineObject,
  Material,
} from "../hooks/useDrawContext";
import MaterialObject from "./MaterialObject";

import { getGridBounds, getOffset, makeGrid } from "../utils/DetailUtils";
import useCanvasContext, { JointObject } from "../components/CanvasContext";
import { isLineObject } from "../utils/ObjectUtils";
import { joinSegment, offsetSegment } from "../utils/GeomUtils";
import { useMemo } from "react";

const DetailObject = ({
  design_object,
  detail,
  selected,
  is_locked,
  disabled,
}: {
  design_object: DesignObject;
  detail: Detail;
  selected: boolean;
  is_locked: boolean;
  disabled?: boolean;
}) => {
  const {
    getDesignObject,
    getOverriddenDesignObject,
    getOverriddenNamedObject,
  } = useDrawContext();
  const { canvasContext } = useCanvasContext();

  const getMaterialsAndBoundaries = () => {
    // line objects only
    if (!isLineObject(design_object)) {
      return { materials: [], boundaries: [] };
    }

    // no detail
    if (detail.internalDetails.length === 0) {
      return { materials: [], boundaries: [] };
    }

    let cross_section = getOverriddenNamedObject(
      design_object.details[0]?.internalDetails[0]
    ) as CrossSection;
    let materials = cross_section.materials?.map(
      getOverriddenNamedObject
    ) as Material[];

    let segment = design_object.geometry.points.slice();

    let offsets = getOffset(cross_section, materials);

    let joints = [];
    if (detail.boundaryDetails.length > 0) {
      joints = Object.values(canvasContext.jointObjects).filter(
        (joint) =>
          joint.anchors.filter(
            (anchor) => anchor.designObject.uuid === design_object.uuid
          )?.[0]
      );
    }

    // calculate grid and bounds
    let start_joint = joints.filter(
      (joint) =>
        joint.anchors.filter(
          (anchor) =>
            anchor.designObject.uuid === design_object.uuid &&
            anchor.pointIndex === 0
        )?.[0]
    )?.[0] as JointObject;

    let end_joint = joints.filter(
      (joint) =>
        joint.anchors.filter(
          (anchor) =>
            anchor.designObject.uuid === design_object.uuid &&
            anchor.pointIndex === 1
        )?.[0]
    )?.[0] as JointObject;

    let start_grid;
    let start_bound;
    let end_grid;
    let end_bound;

    if (start_joint) {
      let des1 = getOverriddenDesignObject(
        start_joint.anchors[0]?.designObject
      ) as LineObject;
      let des2 = getOverriddenDesignObject(
        start_joint.anchors[1]?.designObject
      ) as LineObject;
      let section1 = getOverriddenNamedObject(
        des1?.details[0]?.internalDetails[0]
      ) as CrossSection;
      let section2 = getOverriddenNamedObject(
        des2?.details[0]?.internalDetails[0]
      ) as CrossSection;
      let materials1 = section1?.materials?.map(
        getOverriddenNamedObject
      ) as Material[];
      let materials2 = section2?.materials?.map(
        getOverriddenNamedObject
      ) as Material[];
      start_grid = makeGrid(
        start_joint,
        des1,
        des2,
        section1,
        section2,
        materials1,
        materials2
      ).grid;
      if (start_grid) {
        start_bound =
          getGridBounds(start_grid)[
            start_joint.anchors.findIndex(
              (x) => x.designObject.uuid === design_object.uuid
            )
          ];
      }
    }

    if (end_joint) {
      let des1 = getOverriddenDesignObject(
        end_joint.anchors[0]?.designObject
      ) as LineObject;
      let des2 = getOverriddenDesignObject(
        end_joint.anchors[1]?.designObject
      ) as LineObject;
      let section1 = getOverriddenNamedObject(
        des1?.details[0]?.internalDetails[0]
      ) as CrossSection;
      let section2 = getOverriddenNamedObject(
        des2?.details[0]?.internalDetails[0]
      ) as CrossSection;
      let materials1 = section1?.materials?.map(
        getOverriddenNamedObject
      ) as Material[];
      let materials2 = section2?.materials?.map(
        getOverriddenNamedObject
      ) as Material[];
      end_grid = makeGrid(
        end_joint,
        des1,
        des2,
        section1,
        section2,
        materials1,
        materials2
      ).grid;
      if (end_grid) {
        end_bound =
          getGridBounds(end_grid)[
            end_joint.anchors.findIndex(
              (x) => x.designObject.uuid === design_object.uuid
            )
          ];
      }
    }

    return {
      materials,
      boundaries: materials.map((e, i) => {
        let off1 = offsetSegment(segment, offsets[i][0]);
        let off2 = offsetSegment(segment, offsets[i][1]);

        if (start_bound) {
          off1 = joinSegment(start_bound, off1).concat(off1.slice(2));
          off2 = joinSegment(start_bound, off2).concat(off2.slice(2));
        }
        if (end_bound) {
          off1 = off1.slice(0, 2).concat(joinSegment(off1, end_bound));
          off2 = off2.slice(0, 2).concat(joinSegment(off2, end_bound));
        }

        return off1.concat(off2.slice(2).concat(off2.slice(0, 2)));
      }),
    };
  };

  const { materials, boundaries } = getMaterialsAndBoundaries();
  // useMemo(getMaterialsAndBoundaries, [
  //   design_object,
  //   detail,
  //   selected,
  //   is_locked,
  //   disabled,
  // ]);
  // useMemo(() => {
  //   console.log("new bd");
  //   return
  // }, [design_object, detail.internalDetails, detail.boundaryDetails]);

  return (
    <Group onClick={console.log}>
      {materials.map((material, i) => (
        <MaterialObject
          key={i}
          design_object={design_object}
          boundary={boundaries[i]}
          active_sides={[true, false, true, false]}
          material={material}
          selected={selected}
          disabled={disabled}
          onClick={console.log}
        />
      ))}
    </Group>
  );
};

export default DetailObject;
