import BoundaryDetailBlock, { BlockClickArea } from "./BoundaryDetailBlock";
import { JointObject } from "../components/CanvasContext";
import { useMemo } from "react";
import {
  BlockProps,
  calculateGrid,
  GridProps,
  makeGrid,
} from "../utils/DetailUtils";
import { Group } from "../utils/Geometry";
import useProjectContext from "../hooks/useProjectContext";
import { _3DLineObject, ThicknessMaterialObject } from "../type/GeometryObject";
import { CrossSection, SectionJoin } from "../type/Detail";
import { AbstractArray } from "../type/AbstractArray";

const BoundaryDetailGrid = ({ joint }: { joint: JointObject }) => {
  const { project_context, setProjectContext } = useProjectContext();

  // const [grid, setGrid] = useState<GridProps>({
  //   blocks: [],
  //   i_reverse: false,
  //   j_reverse: false,
  // });

  let des1 = project_context.project.get(
    joint.anchors[0]?.drawn_object
  ) as _3DLineObject;
  let des2 = project_context.project.get(
    joint.anchors[1]?.drawn_object
  ) as _3DLineObject;
  let section1 = project_context.project.get(des1?.details[0]) as CrossSection;
  let section2 = project_context.project.get(des2?.details[0]) as CrossSection;
  let materials1 = section1?.materials
    ?.getElements()
    .map(project_context.project.get) as ThicknessMaterialObject[];
  let materials2 = section2?.materials
    ?.getElements()
    .map(project_context.project.get) as ThicknessMaterialObject[];
  // let easy_join = project_context.project.get(
  //   des1?.details[0]?.boundaryDetails[0]
  // );
  let easy_join = project_context.project.get(joint.detail) as SectionJoin;

  let grid_data = useMemo(
    () =>
      makeGrid(joint, des1, des2, section1, section2, materials1, materials2),
    [joint, des1, des2, section1, section2, materials1, materials2]
  );

  let calculatedGrid = useMemo(
    () => calculateGrid(grid_data.grid, section1, section2, easy_join),
    [grid_data.grid, section1, section2, easy_join]
  ) as GridProps;

  if (!calculatedGrid) {
    return;
  }

  // event handler
  const handleClick = (e) => {
    let block = e.eventObject.block as BlockProps;

    let active_material = block.materials[block.active_object_index];
    let inactive_material = block.materials[1 - block.active_object_index];

    let priority = easy_join.priorities.getElements().slice();

    let active_index = easy_join.priorities
      .getElements()
      .findIndex((x) => x.uuid === active_material.uuid);
    let inactive_index = easy_join.priorities
      .getElements()
      .findIndex((x) => x.uuid === inactive_material.uuid);

    // console.log([active_index, inactive_index]);
    // console.log([
    //   getNamedObject(active_material).name,
    //   getNamedObject(inactive_material).name,
    // ]);

    if (active_index !== -1 && inactive_index !== -1) {
      if (active_index > inactive_index) {
        priority.splice(inactive_index, 1);

        let newIndexA = priority.findIndex(
          (x) => x.uuid === active_material.uuid
        );
        priority.splice(newIndexA + 1, 0, inactive_material);
      } else {
        priority.splice(active_index, 1);
        let newIndexB = priority.findIndex(
          (x) => x.uuid === inactive_material.uuid
        );
        priority.splice(newIndexB, 0, active_material);
      }
    } else if (active_index === -1 && inactive_index !== -1) {
      priority.splice(inactive_index, 0, active_material);
    } else if (inactive_index === -1 && active_index !== -1) {
      priority.splice(active_index + 1, 0, inactive_material);
    } else {
      priority.push(active_material, inactive_material);
    }

    // let mat_names = priority.map((x) => getNamedObject(x).name);
    // console.log(mat_names);

    joint.detail = {
      priorities: new AbstractArray(priority),
      ...joint.detail,
    } as SectionJoin;

    // updateDesignObject(des1, {
    //   details: [
    //     {
    //       internalDetails: des1.details[0].internalDetails,
    //       boundaryDetails: [
    //         {
    //           ...des1.details[0].boundaryDetails[0],
    //           priorities: priority,
    //         },
    //       ] as EasyJoin[],
    //     },
    //   ],
    // });

    // updateDesignObject(des2, {
    //   details: [
    //     {
    //       internalDetails: des2.details[0].internalDetails,
    //       boundaryDetails: [
    //         {
    //           ...des2.details[0].boundaryDetails[0],
    //           priorities: priority,
    //         },
    //       ] as EasyJoin[],
    //     },
    //   ],
    // });
  };

  return (
    <Group>
      {calculatedGrid.blocks.flat().map((block) => (
        <BoundaryDetailBlock
          block={block}
          u_flip={grid_data.u_flip}
          v_flip={grid_data.v_flip}
          flip_uv={grid_data.flip_uv}
        />
      ))}
      {calculatedGrid.blocks
        .flat()
        .map((block) =>
          block.editable ? (
            <BlockClickArea block={block} onClick={handleClick} />
          ) : null
        )}
    </Group>
  );
};

export default BoundaryDetailGrid;
