import List from "../components/List";

import styles from "./CYPage.module.scss";
import classNames from "classnames/bind";
import NavButton from "../components/NavButton";
import { useEffect, useRef, useState } from "react";
import Division from "../components/Division";
import NavSection from "../components/NavSection";
import DrawSection from "../components/DrawSection";
import PropertySection from "../components/PropertySection";
import { GlobalKeyDownProvider } from "../hooks/useGlobalKeyDown";
import { ProjectContextProvider } from "../hooks/useProjectContext";
// import { DrawContextProvider } from "../hooks/useDrawContext";

const cx = classNames.bind(styles);

const nav_menu = {
  type: "menu",
  illust: "Menu",
  commands: ["ME"],
};

const nav_setting = {
  type: "settings",
  illust: "Settings",
  commands: ["SET"],
};

const nav_buttons = [
  { type: "select", illust: "Quick Select", commands: ["QS"] },
  { type: "layers", illust: "Layers", commands: ["LA"] },
  { type: "dependencies", illust: "Dependencies", commands: ["DE"] },
  { type: "assets", illust: "Assets", commands: ["AS"] },
  { type: "styles", illust: "Styles", commands: ["ST"] },
];

const CYPage = () => {
  const [nav_clicked, setNavClicked] = useState(false);
  const getHandleNavClick = (nav_data) => {
    return () => {
      setNavClicked((prev) => (prev === nav_data.type ? false : nav_data.type));
    };
  };

  return (
    <GlobalKeyDownProvider>
      <ProjectContextProvider>
        <div className={cx("wrapper")}>
          {/* <h1>hello, this is changyong's javascript file page</h1> */}
          <List type="row" attach="start" gap={0} fill fillY>
            <div className={cx("frame-nav")}>
              <List type="column" attach="start" fill>
                <div style={{ minHeight: "48px" }}>
                  <NavButton
                    {...nav_menu}
                    onClick={getHandleNavClick(nav_menu)}
                    focus={nav_clicked === nav_menu.type}
                  />
                </div>
                <Division />
                <List type="column" attach="start" fill>
                  {nav_buttons.map((e, idx) => (
                    <NavButton
                      key={idx}
                      {...e}
                      onClick={getHandleNavClick(e)}
                      focus={nav_clicked === e.type}
                    />
                  ))}
                </List>
                <Division />
                <div style={{ minHeight: "64px" }}>
                  {/* <List type="column" attach="start" style={{ height: "2rem" }}> */}
                  <NavButton
                    {...nav_setting}
                    onClick={getHandleNavClick(nav_setting)}
                    focus={nav_clicked === nav_setting.type}
                  />
                  {/* </List> */}
                </div>
              </List>
            </div>
            {nav_clicked === "layers" && <NavSection />}
            <DrawSection />
            <PropertySection />
          </List>
        </div>
      </ProjectContextProvider>
    </GlobalKeyDownProvider>
  );
};

export default CYPage;
