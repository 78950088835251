import { closestPoint } from "./GeomUtils";
import { ThreeEvent, extend } from "@react-three/fiber";
import { Vector3 } from "three";
import * as THREE from "three";
import { MeshLineGeometry, MeshLineMaterial } from "meshline";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { Layer } from "../type/Layer";
import { ProjectClass } from "../type/Project";
extend({
  Line2,
  LineMaterial,
  LineGeometry,
  MeshLineGeometry,
  MeshLineMaterial,
});

// Get current pointer position relative to canvas
export const getNormalizedPosition = (e: any) => {
  const rect = e.target?.getBoundingClientRect?.();

  if (!rect || !e.clientX || !e.clientY) return;

  // Convert pointer position from screen to normalized device coordinates (NDC)
  const ndcX = ((e.clientX - rect.left) / rect.width) * 2 - 1;
  const ndcY = -((e.clientY - rect.top) / rect.height) * 2 + 1;

  return { x: ndcX, y: ndcY };
};

// Get the current pointer position and adjust it based on stage position
export const getWorldPosition = (e: any, camera: any) => {
  const ndcPos = getNormalizedPosition(e);

  if (!ndcPos) return;

  const vec = new Vector3(ndcPos.x, ndcPos.y, 0);
  vec.unproject(camera);

  vec.z = 0;

  return vec;
};

// Snap mouse position
export const getSnappedPoint = (
  e: ThreeEvent<PointerEvent>,
  camera: any,
  baseLinePoints: THREE.Vector3[],
  scale: number,
  z: number = 0
) => {
  const snapDistance = 20 / scale;
  const pos = getWorldPosition(e, camera);
  const currentPos = pos ? pos : new THREE.Vector3(0, 0, 0);

  if (baseLinePoints.length > 0) {
    const snapCandidate = closestPoint(baseLinePoints, currentPos);
    if (snapCandidate.distanceTo(currentPos) <= snapDistance) {
      return snapCandidate;
    }
  }
  return pos;
};

export const vectorize = (coords, z = 0) => {
  const res = [];
  for (let i = 0; i < coords.length; i += 2) {
    res.push(new Vector3(coords[i], coords[i + 1], z));
  }
  return res;
};

// layer to linestyle
export const layerToLinestyle = (layer: Layer, project: ProjectClass) => {
  const color = project.get(layer.color);
  return {
    stroke:
      project.global_setting.rendering_style !== "layer"
        ? color.ctb_color ?? "black"
        : project.get(layer.color).value,
    fill:
      project.global_setting.rendering_style !== "layer"
        ? color.ctb_color ?? "black"
        : project.get(layer.color).value,
    strokeWidth:
      project.global_setting.rendering_style === "print"
        ? color.ctb_lineweight * project.global_setting.scale_denominator
        : 1,
  };
};

export const leftClickOnly = (originalFunction) => {
  return (event, ...args) => {
    // 좌클릭 (button: 0) 여부 확인
    if (event.button === 0) {
      return originalFunction.call(this, event, ...args);
    }
  };
};
