import { Fragment, useEffect, useMemo, useState } from "react";
import List from "./List";

import styles from "./CommandFrame.module.scss";
import classNames from "classnames/bind";
import IconCommand from "./IconCommand";
import Tooltip from "./Tooltip";
import CommandSuggests from "./CommandSuggests";
import useGlobalKeyDown from "../hooks/useGlobalKeyDown";
import useDrawContext from "../hooks/useDrawContext";

const cx = classNames.bind(styles);

const sample_commands = [
  [
    { name: "select", illust: "Select", commands: ["SE", "Esc"] },
    { name: "pan", illust: "Pan", commands: ["PA", "Space+LClick", "RClick"] },
    { name: "zoom", illust: "Zoom", commands: ["Z", "Scroll"] },
  ],
  [
    { name: "line", illust: "Line", commands: ["L"] },
    { name: "polyline", illust: "Polyline", commands: ["PL"] },
    { name: "text", illust: "Text", commands: ["TE"] },
  ],
  [
    { name: "copy", illust: "Copy", commands: ["C"] },
    { name: "move", illust: "Move", commands: ["M"] },
    { name: "scale", illust: "Scale", commands: ["SC"] },
  ],
  // [
  //   { name: "group", illust: "Group", commands: ["G", "Ctrl+G"] },
  //   { name: "block", illust: "Block", commands: ["BL"] },
  //   { name: "generator", illust: "Generator", commands: ["GE"] },
  // ],
];

const CommandButton = ({ name, illust, commands }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className={cx("btn-command")}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        setHover(false);
      }}
    >
      <IconCommand type={name} />
      {hover && (
        <Tooltip type="top" commands={commands}>
          {illust}
        </Tooltip>
      )}
    </div>
  );
};

const CommandFrame = ({}: any) => {
  const {
    pressed_keys,
    pressed_text,
    current_action,
    setPressedKeys,
    resetKeys,
  } = useGlobalKeyDown();
  const { draw_context, setDrawContext } = useDrawContext();

  const [command_data, setCommandData] = useState({
    active_command: null,
    command_text: null,
    command_options: [],
  });

  useEffect(() => {
    if (pressed_text === "l" && current_action === "next") {
      setDrawContext({ mode: "polyline" });
      resetKeys();
    } else if (
      pressed_text === "" &&
      current_action === "next" &&
      draw_context.mode === "base"
    ) {
      setDrawContext({ mode: "polyline" });
      resetKeys();
    } else if (current_action === "next" || current_action === "exit") {
      setDrawContext({ mode: "base" });
      resetKeys();
    }
  }, [pressed_text, current_action, draw_context.mode]);

  useEffect(() => {
    if (draw_context.mode === "base" && pressed_text === "") {
      setCommandData({
        active_command: null,
        command_text: "press any **Key** to type command",
        command_options: [],
      });
    } else if (draw_context.mode === "polyline") {
      if (draw_context.step === 0) {
        setCommandData({
          active_command: "polyline",
          command_text: "Start **Point** of Line",
          command_options: [
            { key: "**B**othSides" },
            { key: "**N**ormal" },
            { key: "**V**ertical" },
          ],
        });
      } else {
        setCommandData({
          active_command: "polyline",
          command_text: "End **Point** of Line",
          command_options: [{ key: "**B**othSides" }],
        });
      }
    } else {
      setCommandData({
        active_command: null,
        command_text: null,
        command_options: [],
      });
    }
  }, [draw_context.mode, draw_context.step, pressed_text]);

  return (
    <List fill>
      <div className={cx("frame-top")}>
        <List type="row">
          {sample_commands.map((e, idx) => (
            <Fragment key={idx}>
              {e.map((e2, idx2) => (
                <CommandButton key={idx2} {...e2} />
              ))}
              <div className={cx("btn-command")}>
                <IconCommand type="more" />
              </div>
              {idx !== sample_commands.length - 1 && (
                <IconCommand type="divider" />
              )}
            </Fragment>
          ))}
        </List>
      </div>
      <div className={cx("frame-bottom")}>
        <div className={cx("frame-command-line")}>
          <List type="row" align="center" fill fillY>
            <div className={cx("frame-command-symbol")}>
              <List attach="center" fill>
                <IconCommand type={command_data.active_command ?? "command"} />
              </List>
            </div>
            <div className={cx("frame-command-text")}>
              <List type="row" align="center" gap={0.25} fill fillY>
                {command_data.command_text && (
                  <div>
                    {command_data.command_text?.split("**")?.map((e, idx) =>
                      idx % 2 === 0 ? (
                        e
                      ) : (
                        <b key={idx} className={cx("txt-highlight")}>
                          {e}
                        </b>
                      )
                    )}
                  </div>
                )}
                <List type="row" align="center" style={{ height: "100%" }}>
                  {command_data.command_options.map((e, idx) => (
                    <div className={cx("frame-option")} key={idx}>
                      <List type="row" align="center" fill fillY>
                        {idx === 0 ? "(" : ""}
                        {e.key?.split("**").map((e2, idx2) =>
                          idx2 % 2 === 0 ? (
                            e2
                          ) : (
                            <b key={idx2} className={cx("txt-command")}>
                              {e2}
                            </b>
                          )
                        )}
                        {e.value && `=${e.value}`}
                        {idx === command_data.command_options.length - 1
                          ? "):"
                          : ""}
                      </List>
                    </div>
                  ))}
                  {pressed_text}
                </List>
              </List>
            </div>
            <div
              className={cx("frame-command-done")}
              onClick={() => {
                if (draw_context.mode !== "base") {
                  setDrawContext({ mode: "base" });
                  setPressedKeys([]);
                }
              }}
            >
              <List attach="center" fill>
                Done
              </List>
            </div>
          </List>
        </div>
        {pressed_text.length > 0 && <CommandSuggests />}
      </div>
    </List>
  );
};

export default CommandFrame;
