import { getCorrectColor, getScaleDenominator } from "../utils/ObjectUtils";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import PropertyRow from "./PropertyRow";

import styles from "./PropertyGroupForBackground.module.scss";
import classNames from "classnames/bind";

import DropdownCommand from "./DropdownCommand";

import useProjectContext from "../hooks/useProjectContext";

const cx = classNames.bind(styles);

const PropertyGroupForBackground = () => {
  const { project_context, setProjectContext } = useProjectContext();
  return (
    <PropertyGroup
      title="Window Style"
      actions={[
        { icon_type: "assets", illust: "Assets" },
        { icon_type: "add", illust: "Add" },
      ]}
    >
      <PropertyRow>
        <PropertyBlock
          type="text-input-fill"
          name="Window Color"
          value={project_context.project.global_setting.background_color.toUpperCase()}
          onBlur={(event) => {
            project_context.project.updateGlobalSetting({
              background_color: getCorrectColor(
                event.target?.value,
                project_context.project.global_setting.background_color
              ),
            });
          }}
        ></PropertyBlock>
        <PropertyBlock
          type="action"
          icon_type="match"
          illust="Pick Color"
        ></PropertyBlock>
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock
          type="text-selection-fill"
          name="Render Style"
          value={project_context.project.global_setting.rendering_style}
          use_dropdown
        >
          <DropdownCommand
            onClick={(event) =>
              project_context.project.updateGlobalSetting({
                rendering_style: "print",
              })
            }
          >
            print style
          </DropdownCommand>
          <DropdownCommand
            onClick={(event) =>
              project_context.project.updateGlobalSetting({
                rendering_style: "layer",
              })
            }
          >
            layer style
          </DropdownCommand>
          <DropdownCommand
            onClick={(event) =>
              project_context.project.updateGlobalSetting({
                rendering_style: "uniform",
              })
            }
          >
            uniform style
          </DropdownCommand>
        </PropertyBlock>
      </PropertyRow>
      {project_context.project.global_setting.rendering_style === "print" && (
        <PropertyRow>
          <PropertyBlock
            type="text-input-fill"
            name="Scale"
            value={
              "1/" + project_context.project.global_setting.scale_denominator
            }
            onBlur={(event) => {
              project_context.project.updateGlobalSetting({
                scale_denominator: getScaleDenominator(
                  event.target?.value,
                  project_context.project.global_setting.scale_denominator
                ),
              });
            }}
          ></PropertyBlock>
        </PropertyRow>
      )}
    </PropertyGroup>
  );
};

export default PropertyGroupForBackground;
