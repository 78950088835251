import { Abstract, DesignatorObject } from "../type/DesignatorObject";
import { ProjectClass } from "../type/Project";
import {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { v4 } from "uuid";
import {
  getInitialProjectContext,
  setInitialProject,
} from "./initialProjectContext";

const AllModes = ["base", "polyline", "edit"];
type ModeTuple = typeof AllModes;
export type Mode = ModeTuple[number];

export interface ProjectContextStateProps {
  mode: Mode;
  step: number;
  scope: Abstract<DesignatorObject> | null;
  current_selection: Abstract<DesignatorObject>[];

  project: ProjectClass;
}

export interface ProjectContextActionProps {
  mode?: Mode;
  step?: number;
  scope?: Abstract<DesignatorObject> | null;
  current_selection?: Abstract<DesignatorObject>[];

  project?: ProjectClass;
}

export interface ProjectContextProps {
  project_context: ProjectContextStateProps;
  setProjectContext: Dispatch<ProjectContextActionProps>;
}

export const ProjectContext = createContext<ProjectContextProps>(null);

const reduceProjectContext: Reducer<
  ProjectContextStateProps,
  | ProjectContextActionProps
  | ((prev: ProjectContextStateProps) => ProjectContextActionProps)
> = (
  state: ProjectContextStateProps,
  action:
    | ProjectContextActionProps
    | ((prev: ProjectContextStateProps) => ProjectContextActionProps)
) => {
  if (typeof action === "function") {
    action = action(state);
  }
  // console.log(state?.project?.named_objects, action?.project?.named_objects);
  return { ...state, ...action };
};

export const ProjectContextProvider = ({ children }) => {
  const [project_context, setProjectContext] = useReducer<
    Reducer<
      ProjectContextStateProps,
      | ProjectContextActionProps
      | ((prev: ProjectContextStateProps) => ProjectContextActionProps)
    >
  >(reduceProjectContext, getInitialProjectContext());

  useEffect(() => {
    // set setProjectContext to ProjectModel
    const initial_project = new ProjectClass(
      project_context.project.uuid,
      project_context.project.layers,
      project_context.project.named_objects,
      project_context.project.drawn_objects,
      project_context.project.global_setting,
      setProjectContext
    );

    setProjectContext({ project: initial_project });

    // add named objects to project
    setInitialProject(initial_project);
  }, []);

  return (
    <ProjectContext.Provider value={{ project_context, setProjectContext }}>
      {children}
    </ProjectContext.Provider>
  );
};

const useProjectContext = () => {
  return useContext(ProjectContext);
};

export default useProjectContext;
