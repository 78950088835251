import { v4 } from "uuid";
import { DrawnObject, ProjectClass } from "../type/Project";
import type { ProjectContextStateProps } from "./useProjectContext";
import {
  ColorStyle,
  HatchType,
  Layer,
  LineType,
  LineWeight,
} from "../type/Layer";
import { FixedRef, NamedObject } from "../type/DesignatorObject";
import { CrossSection, Material, SectionJoin } from "../type/Detail";
import { ThicknessMaterialObject } from "../type/GeometryObject";
import { AbstractArray } from "../type/AbstractArray";
import _ from "lodash";

const abstractify = (obj) => {
  return { uuid: obj.uuid, type: obj.type };
};

export const getInitialProjectContext = (): ProjectContextStateProps => {
  const new_project = new ProjectClass(
    v4(),
    {
      all: [],
      current: null,
    },
    {},
    {},
    {
      uuid: v4(),
      type: "GlobalSetting",
      background_color: "#ffffff",
      rendering_style: "print",
      scale_denominator: 20,
    },
    null
  );

  return {
    mode: "base",
    step: 0,
    scope: null,
    current_selection: [],

    project: new_project,
  };
};

export const setInitialProject = (project: ProjectClass) => {
  const linetypes: {
    [name: string]: NamedObject<LineType> | FixedRef<LineType>;
  } = {
    linetype1: {
      uuid: v4(),
      type: "LineType",
      name: "linetype1",
    },
  };

  const lineweights: {
    [name: string]: NamedObject<LineWeight> | FixedRef<LineWeight>;
  } = {
    lineweight1: {
      uuid: v4(),
      name: "lineweight1",
      type: "LineWeight",
      value: 2,
    },
  };

  const hatchtypes: {
    [name: string]: NamedObject<HatchType> | FixedRef<HatchType>;
  } = {
    ins1: {
      uuid: v4(),
      name: "Insulation 1",
      type: "HatchType",
      value: "Ins2",
    },
    diag1: {
      uuid: v4(),
      name: "Diagonal 1",
      type: "HatchType",
      value: "Diag1",
    },
    pt1: {
      uuid: v4(),
      name: "Scatterd Point 1",
      type: "HatchType",
      value: "Pt1",
    },
    pt2: {
      uuid: v4(),
      name: "Scatterd Point 1",
      type: "HatchType",
      value: "Pt1",
      scale: 0.5,
    },
    cross1: {
      uuid: v4(),
      name: "Scatterd Point 1",
      type: "HatchType",
      value: "Cross1",
    },
    conc1: {
      uuid: v4(),
      name: "Concrete 1",
      type: "HatchType",
      value: "Conc1",
    },
  };

  const colors: {
    [name: string]: NamedObject<ColorStyle> | FixedRef<ColorStyle>;
  } = {
    cad1: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#ff0000",
      name: "CAD 1",
      ctb_color: "#000000",
      ctb_lineweight: 0.08,
    },
    cad2: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#ffff00",
      name: "CAD 2",
      ctb_color: "#000000",
      ctb_lineweight: 0.35,
    },
    cad3: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#00ff00",
      name: "CAD 3",
      ctb_color: "#000000",
      ctb_lineweight: 0.24,
    },
    cad4: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#00ffff",
      name: "CAD 4",
      ctb_color: "#000000",
      ctb_lineweight: 0.13,
    },
    cad5: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#0000ff",
      name: "CAD 5",
      ctb_color: "#000000",
      ctb_lineweight: 0.1,
    },
    cad6: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#ff00ff",
      name: "CAD 6",
      ctb_color: "#000000",
      ctb_lineweight: 0.1,
    },
    cad7: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#ffffff",
      name: "CAD 7",
      ctb_color: "#000000",
      ctb_lineweight: 0.18,
    },
    cad8: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#808080",
      name: "CAD 8",
      ctb_color: "#808080",
      ctb_lineweight: 0.08,
    },
    cad9: {
      uuid: v4(),
      type: "ColorStyle",
      value: "#c0c0c0",
      name: "CAD 9",
      ctb_color: "#505050",
      ctb_lineweight: 0.1,
    },
  };

  // make refs of linetypes, lineweights, hatchtypes, colors

  [linetypes, lineweights, hatchtypes, colors].forEach((objects) => {
    Object.entries(objects).forEach(([key, value]) => {
      project.add(value);
      const fixed_ref = project.makeFixedRef(value);
      objects[key] = fixed_ref;
    });
  });

  // layers

  const layers: { [name: string]: NamedObject<Layer> } = {
    admin: {
      uuid: "admin",
      type: "Layer",
      name: "admin",
      activated: true,
      linetype: _.cloneDeep(linetypes.linetype1),
      lineweight: _.cloneDeep(lineweights.lineweight1),
      color: _.cloneDeep(colors.cad8),
    },
    default: {
      uuid: v4(),
      type: "Layer",
      name: "Default",
      activated: true,
      linetype: _.cloneDeep(linetypes.linetype1),
      lineweight: _.cloneDeep(lineweights.lineweight1),
      color: _.cloneDeep(colors.cad3),
    },
    fin1: {
      uuid: v4(),
      type: "Layer",
      name: "Finish",
      activated: true,
      linetype: _.cloneDeep(linetypes.linetype1),
      lineweight: _.cloneDeep(lineweights.lineweight1),
      color: _.cloneDeep(colors.cad1),
    },
    conc1: {
      uuid: v4(),
      type: "Layer",
      name: "Concrete",
      activated: true,
      linetype: _.cloneDeep(linetypes.linetype1),
      lineweight: _.cloneDeep(lineweights.lineweight1),
      color: _.cloneDeep(colors.cad2),
    },
    ins1: {
      uuid: v4(),
      type: "Layer",
      name: "Insulation",
      activated: true,
      linetype: _.cloneDeep(linetypes.linetype1),
      lineweight: _.cloneDeep(lineweights.lineweight1),
      color: _.cloneDeep(colors.cad6),
    },
    mort1: {
      uuid: v4(),
      type: "Layer",
      name: "Mortar",
      activated: true,
      linetype: _.cloneDeep(linetypes.linetype1),
      lineweight: _.cloneDeep(lineweights.lineweight1),
      color: _.cloneDeep(colors.cad5),
    },
    hatch: {
      uuid: v4(),
      type: "Layer",
      name: "Hatch",
      activated: true,
      linetype: _.cloneDeep(linetypes.linetype1),
      lineweight: _.cloneDeep(lineweights.lineweight1),
      color: _.cloneDeep(colors.cad5),
    },
    outline: {
      uuid: v4(),
      type: "Layer",
      name: "Outline",
      activated: true,
      linetype: _.cloneDeep(linetypes.linetype1),
      lineweight: _.cloneDeep(lineweights.lineweight1),
      color: _.cloneDeep(colors.cad7),
    },
  };

  // just add layers without namedref
  Object.entries(layers).forEach(([key, value]) => {
    project.add(value);
  });

  // set default layer as current
  project.setLayer(abstractify(layers["default"]));

  // materials

  const materials: {
    [name: string]: NamedObject<Material> | FixedRef<Material>;
  } = {
    ins1: {
      uuid: v4(),
      type: "Material",
      name: "Insulation 1",
      hatch: _.cloneDeep(hatchtypes.ins1),
      hatch_layer: abstractify(layers.hatch),
      outline_layer: abstractify(layers.ins1),
    },
    mort1: {
      uuid: v4(),
      type: "Material",
      name: "Mortar 1",
      hatch: _.cloneDeep(hatchtypes.pt1),
      hatch_layer: abstractify(layers.hatch),
      outline_layer: abstractify(layers.mort1),
    },
    foamconc1: {
      uuid: v4(),
      type: "Material",
      name: "Foamed Concrete 1",
      hatch: _.cloneDeep(hatchtypes.pt2),
      hatch_layer: abstractify(layers.hatch),
      outline_layer: abstractify(layers.mort1),
    },
    cushioning1: {
      uuid: v4(),
      type: "Material",
      name: "Cushioning 1",
      hatch: _.cloneDeep(hatchtypes.cross1),
      hatch_layer: abstractify(layers.hatch),
      outline_layer: abstractify(layers.mort1),
    },
    wood1: {
      uuid: v4(),
      type: "Material",
      name: "Wood 1",
      outline_layer: abstractify(layers.fin1),
    },
    plasterboard1: {
      uuid: v4(),
      type: "Material",
      name: "Plaster 1",
      outline_layer: abstractify(layers.fin1),
    },
    stone1: {
      uuid: v4(),
      type: "Material",
      name: "Stone 1",
      hatch: _.cloneDeep(hatchtypes.diag1),
      hatch_layer: abstractify(layers.hatch),
      outline_layer: abstractify(layers.fin1),
    },
    conc1: {
      uuid: v4(),
      type: "Material",
      name: "Concrete 1",
      hatch: _.cloneDeep(hatchtypes.conc1),
      hatch_layer: abstractify(layers.hatch),
      outline_layer: abstractify(layers.conc1),
      variable: true,
    },
    air1: {
      uuid: v4(),
      type: "Material",
      name: "Air 1",
      outline_layer: abstractify(layers.fin1),
    },
  };

  // make refs of materials

  Object.entries(materials).forEach(
    ([key, value]: [key: string, value: NamedObject<Material>]) => {
      project.add(value);
      const named_ref = project.makeRef(value);
      materials[key] = named_ref;
    }
  );

  // thickness materials

  const thickness_materials: {
    [name: string]:
      | NamedObject<ThicknessMaterialObject>
      | FixedRef<ThicknessMaterialObject>;
  } = {
    ins1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.ins1),
      name: "Floor Insulation 1",
      thickness: 30,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    ins2: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.ins1),
      name: "Floor Insulation 2",
      thickness: 150,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    mort1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.mort1),
      name: "Mortar 1",
      thickness: 40,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    foamconc1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.foamconc1),
      name: "Foamed Concrete 1",
      thickness: 30,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    cushioning1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.cushioning1),
      name: "Cushioning 1",
      thickness: 20,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    fin1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.wood1),
      name: "Floor Finish 1",
      thickness: 20,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    plasterboard1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.plasterboard1),
      name: "Plaster Board 1",
      thickness: 10,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    plasterboard2: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.plasterboard1),
      name: "Plaster Board 2",
      thickness: 10,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    stone1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.stone1),
      name: "Stone 1",
      thickness: 30,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    conc1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.conc1),
      name: "Concrete 1",
      thickness: 200,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
    air1: {
      uuid: v4(),
      type: "ThicknessMaterialObject",
      layer: layers.admin,
      material: _.cloneDeep(materials.air1),
      name: "Air Layer 1",
      thickness: 70,
      geometry: { type: "_1DPointGeometry", point: 0 },
      details: [],
      additional_params: {},
    },
  };

  // make refs of thickness materials

  Object.entries(thickness_materials).forEach(
    ([key, value]: [
      key: string,
      value: NamedObject<ThicknessMaterialObject>
    ]) => {
      project.add(value);
      const named_ref = project.makeRef(value);
      thickness_materials[key] = named_ref;
    }
  );

  // cross sections

  const cross_sections: {
    [name: string]: NamedObject<CrossSection> | FixedRef<CrossSection>;
  } = {
    cs1: {
      uuid: v4(),
      name: "Floor Section 1",
      type: "CrossSection",
      base_coordinate: { type: "center", material_index: 2, value: 0 },
      materials: new AbstractArray([
        _.cloneDeep(thickness_materials.fin1),
        _.cloneDeep(thickness_materials.mort1),
        _.cloneDeep(thickness_materials.conc1),
        _.cloneDeep(thickness_materials.ins2),
      ]),
      thickness: 300,
    },
    cs2: {
      uuid: v4(),
      name: "Floor Section 2",
      type: "CrossSection",
      base_coordinate: { type: "center", material_index: 5, value: 0 },
      materials: new AbstractArray([
        _.cloneDeep(thickness_materials.fin1),
        _.cloneDeep(thickness_materials.mort1),
        _.cloneDeep(thickness_materials.foamconc1),
        _.cloneDeep(thickness_materials.ins1),
        _.cloneDeep(thickness_materials.mort1),
        _.cloneDeep(thickness_materials.conc1),
      ]),
      thickness: 290,
    },
    cs3: {
      uuid: v4(),
      name: "Wall Section 1",
      type: "CrossSection",
      base_coordinate: { type: "center", material_index: 3, value: 0 },
      materials: new AbstractArray([
        _.cloneDeep(thickness_materials.plasterboard1),
        _.cloneDeep(thickness_materials.plasterboard2),
        _.cloneDeep(thickness_materials.ins2),
        _.cloneDeep(thickness_materials.conc1),
        _.cloneDeep(thickness_materials.air1),
        _.cloneDeep(thickness_materials.stone1),
      ]),
      thickness: 33,
    },
    cs4: {
      uuid: v4(),
      name: "Wall Section 2",
      type: "CrossSection",
      base_coordinate: { type: "center", material_index: 2, value: 2 },
      materials: new AbstractArray([
        _.cloneDeep(thickness_materials.fin1),
        _.cloneDeep(thickness_materials.conc1),
        _.cloneDeep(thickness_materials.mort1),
        _.cloneDeep(thickness_materials.stone1),
      ]),
      thickness: 33,
    },
  };

  // make refs of cross sections

  Object.entries(cross_sections).forEach(
    ([key, value]: [key: string, value: NamedObject<CrossSection>]) => {
      project.add(value);
      const named_ref = project.makeRef(value);
      cross_sections[key] = named_ref;
    }
  );

  // section joins
  const section_joins: {
    [name: string]: NamedObject<SectionJoin> | FixedRef<SectionJoin>;
  } = {
    ej1: {
      uuid: v4(),
      name: "Wall join 1",
      type: "SectionJoin",
      priorities: new AbstractArray([
        _.cloneDeep(thickness_materials.fin1),
        _.cloneDeep(thickness_materials.plasterboard1),
        _.cloneDeep(thickness_materials.plasterboard2),
        _.cloneDeep(thickness_materials.air1),
        _.cloneDeep(thickness_materials.mort1),
        _.cloneDeep(thickness_materials.foamconc1),
        _.cloneDeep(thickness_materials.ins2),
        _.cloneDeep(thickness_materials.ins1),
        _.cloneDeep(thickness_materials.stone1),
        _.cloneDeep(thickness_materials.conc1),
      ]),
    },
    ej2: {
      uuid: v4(),
      name: "Wall join 2",
      type: "SectionJoin",
      priorities: new AbstractArray([
        _.cloneDeep(thickness_materials.fin1),
        _.cloneDeep(thickness_materials.mort1),
        _.cloneDeep(thickness_materials.ins1),
        _.cloneDeep(thickness_materials.stone1),
        _.cloneDeep(thickness_materials.conc1),
      ]),
    },
    ej3: {
      uuid: v4(),
      name: "Wall join 3",
      type: "SectionJoin",
      priorities: new AbstractArray([
        _.cloneDeep(thickness_materials.fin1),
        _.cloneDeep(thickness_materials.mort1),
        _.cloneDeep(thickness_materials.ins1),
        _.cloneDeep(thickness_materials.stone1),
        _.cloneDeep(thickness_materials.conc1),
      ]),
    },
    ej4: {
      uuid: v4(),
      name: "Wall join Reversed",
      type: "SectionJoin",
      priorities: new AbstractArray([
        _.cloneDeep(thickness_materials.conc1),
        _.cloneDeep(thickness_materials.stone1),
        _.cloneDeep(thickness_materials.ins1),
        _.cloneDeep(thickness_materials.mort1),
        _.cloneDeep(thickness_materials.fin1),
      ]),
    },
  };

  // add section joins without namedref

  Object.entries(section_joins).forEach(
    ([key, value]: [key: string, value: NamedObject<SectionJoin>]) => {
      project.add(value);
    }
  );
};
