import { Fragment, useMemo, useState } from "react";
import List from "./List";

import styles from "./PropertySection.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconActionAdd } from "../svgs/IconActionAdd.svg";
import Division from "./Division";
import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import AutoScrollContainer from "./AutoScrollContainer";
import Account from "./Account";

import useProjectContext from "../hooks/useProjectContext";
import PropertySectionForLayer from "./PropertySectionForLayer";
import PropertySectionForDesignObject from "./PropertySectionForDesignObject";
import PropertyGroupForDetail from "./PropertyGroupForDetail";
import PropertyGroupForThicknessMaterial from "./PropertyGroupForThicknessMaterial";
import PropertyGroupForBackground from "./PropertyGroupForBackground";
import { _3DLineObject, ThicknessMaterialObject } from "../type/GeometryObject";
import { isLayer } from "../utils/ObjectUtils";
import { isObjectOfType } from "../type/DesignatorObject";

const cx = classNames.bind(styles);

const PropertySection = ({}: any) => {
  const { project_context } = useProjectContext();
  const { curr, curr_data } = useMemo(() => {
    const curr = project_context.current_selection[0];
    const curr_data = curr ? project_context.project.get(curr) : undefined;

    return {
      curr,
      curr_data,
    };
  }, [project_context]);

  return (
    <div className={cx("wrapper")}>
      <List type="column" attach="start" fill>
        <List
          type="row"
          attach="space"
          padding={0.5}
          fill
          style={{
            minHeight: "2rem",
          }}
        >
          <Account
            symbol="C"
            name="Changyong Kim"
            background_color="#075662"
            color="#f0da55"
          />
          <IconActionAdd />
        </List>
        <Division />

        <AutoScrollContainer>
          <List type="column" attach="start" align="left" fill>
            {!curr_data ? (
              <></>
            ) : isLayer(curr_data) ? (
              <PropertySectionForLayer data={curr_data} />
            ) : isObjectOfType<_3DLineObject>(curr_data, "_3DLineObject") ? (
              <>
                <PropertySectionForDesignObject />
                <PropertyGroupForDetail data={curr_data} />
                <PropertyGroup
                  title="Additional Parameters"
                  actions={[
                    { icon_type: "filter", illust: "Filter" },
                    { icon_type: "add", illust: "Add" },
                  ]}
                  close
                >
                  <PropertyRow>
                    <PropertyBlock
                      type="long-selection"
                      name="IFC type"
                      value="Wall"
                    ></PropertyBlock>
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="long-text"
                      name="Owner"
                      value="Changyong"
                    ></PropertyBlock>
                  </PropertyRow>
                </PropertyGroup>
              </>
            ) : isObjectOfType<ThicknessMaterialObject>(
                curr_data,
                "ThicknessMaterialObject"
              ) ? (
              <>
                <PropertySectionForDesignObject />
                <PropertyGroupForThicknessMaterial data={curr_data} />
                <PropertyGroup
                  title="Additional Parameters"
                  actions={[
                    { icon_type: "filter", illust: "Filter" },
                    { icon_type: "add", illust: "Add" },
                  ]}
                  close
                >
                  <PropertyRow>
                    <PropertyBlock
                      type="long-selection"
                      name="IFC type"
                      value="Wall"
                    ></PropertyBlock>
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="long-text"
                      name="Owner"
                      value="Changyong"
                    ></PropertyBlock>
                  </PropertyRow>
                </PropertyGroup>
              </>
            ) : (
              <PropertyGroupForBackground />
            )}
            <div style={{ minHeight: "8rem", minWidth: "15rem" }}></div>
          </List>
        </AutoScrollContainer>
        <Division />
        <List type="column" attach="start" align="left">
          <PropertyGroup
            title="Project Info"
            actions={[{ icon_type: "more", illust: "More info" }]}
            close
          >
            <PropertyRow>
              <PropertyBlock type="long-text" name="Title" value="Project 1" />
            </PropertyRow>
          </PropertyGroup>
          <PropertyGroup
            title="Drawing Info"
            actions={[{ icon_type: "more", illust: "More info" }]}
            no_divider
            close
          >
            <PropertyRow>
              <PropertyBlock type="long-text" name="No." value="A-701" />
            </PropertyRow>
          </PropertyGroup>
        </List>
        <Division />
        <div style={{ minHeight: "64px" }}>
          <PropertyRow>
            <PropertyBlock type="group-title">Export</PropertyBlock>
            <PropertyBlock
              type="action"
              icon_type="copy"
              illust="Copy"
              // disabled
            />
            <PropertyBlock
              type="action"
              icon_type="download"
              illust="Download"
              onClick={() => {
                const fileUrl = "/241107test.dwg"; // 다운로드할 파일의 경로
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = "Changyong Kim_Project 1_A-701.dxf"; // 다운로드될 파일 이름 지정
                link.click();
              }}
            />
            <PropertyBlock
              type="action"
              icon_type="more"
              illust="More options"
            />
          </PropertyRow>
        </div>
      </List>
    </div>
  );
};

export default PropertySection;
