import { Fragment, useEffect, useRef, useState } from "react";
import List from "./List";

import styles from "./DrawSection.module.scss";
import classNames from "classnames/bind";
import Division from "./Division";
import CommandFrame from "./CommandFrame";
import CustomInput from "./CustomInput";
import CanvasWindow from "./Canvas";
import useDrawContext from "../hooks/useDrawContext";
import { CanvasContextProvider } from "./CanvasContext";
import { isOverwritten } from "../utils/ObjectUtils";

const cx = classNames.bind(styles);

const sample_breadscrum = [
  { text: "Changyong Kim", link: "" },
  { text: "Project 1", link: "" },
  { text: "A-701" },
];

const DrawSection = ({}: any) => {
  const canvas_wrapper = useRef(null);
  const [canvas_size, setCanvasSize] = useState({ width: 0, height: 0 });
  const [is_right_click_drag, setIsRightClickDrag] = useState(false);
  const [is_right_mouse_down, setIsRightMouseDown] = useState(false);
  const { draw_context, setDrawContext, getDesignObject } = useDrawContext();

  useEffect(() => {
    const resizeStage = () => {
      if (canvas_wrapper.current) {
        const width = canvas_wrapper.current.offsetWidth;
        const height = canvas_wrapper.current.offsetHeight;
        setCanvasSize({ width, height });
      }
    };

    // 부모 요소의 크기 변화를 감지하는 ResizeObserver 설정
    const observer = new ResizeObserver(resizeStage);

    // 부모 요소를 감지 대상으로 추가
    if (canvas_wrapper.current) {
      observer.observe(canvas_wrapper.current);
    }

    // 컴포넌트 언마운트 시 옵저버 해제
    return () => {
      observer.disconnect();
    };
  }, []);

  // useEffect(() => {
  const handleMouseDown = (e) => {
    if (e.button === 2) {
      // 우클릭
      setIsRightMouseDown(true); // 우클릭 상태
    }
  };

  const handleMouseMove = (e, is_right_mouse_down) => {
    // 우클릭 상태에서 마우스가 움직이면 드래그 시작으로 간주
    if (is_right_mouse_down) {
      setIsRightClickDrag(true); // 드래그 중
    }
  };

  const handleMouseUp = (e) => {
    if (e.button === 2) {
      // 우클릭에서 손을 뗌
      setIsRightMouseDown(false); // 우클릭 상태 해제
      setIsRightClickDrag(false); // 드래그 종료
    }
  };

  //   if (canvas_wrapper.current) {
  //     canvas_wrapper.current.addEventListener("mousedown", handleMouseDown);
  //     canvas_wrapper.current.addEventListener("mousemove", handleMouseMove);
  //     canvas_wrapper.current.addEventListener("mouseup", handleMouseUp);
  //     return () => {
  //     canvas_wrapper.current.removeEventListener("mousedown", handleMouseDown);
  //     canvas_wrapper.current.removeEventListener("mousemove", handleMouseMove);
  //     canvas_wrapper.current.removeEventListener("mouseup", handleMouseUp);
  //   };
  //   }

  // }, [is_right_mouse_down]);

  return (
    <div
      className={cx("wrapper")}
      // onClick={() => setDrawContext({ current_selection: [] })}
    >
      <List type="column" attach="start" fill>
        <List
          type="row"
          padding={0.5}
          gap={0.125}
          fill
          style={{
            minHeight: "2rem",
          }}
        >
          {sample_breadscrum.map((e, idx) => (
            <Fragment key={idx}>
              {idx !== sample_breadscrum.length - 1 ? (
                <>
                  <div className={cx("frame-breadscrum")}>{e.text}</div>
                  <div className={cx("seperator")}>/</div>
                </>
              ) : (
                <CustomInput
                  className={cx("frame-breadscrum", "input")}
                  initial={e.text}
                  autoselect
                />
              )}
            </Fragment>
          ))}
        </List>
        <Division />
        <div
          ref={canvas_wrapper}
          className={cx(
            "canvas-wrapper",
            draw_context.mode === "polyline" ? "command" : "",
            is_right_click_drag ? "dragging" : ""
          )}
          onContextMenu={(e) => e.preventDefault()}
          onMouseDown={handleMouseDown}
          onMouseMove={(e) => handleMouseMove(e, is_right_mouse_down)}
          onMouseUp={handleMouseUp}
        >
          <List
            type="row"
            padding={0.5}
            gap={0.5}
            style={{ position: "absolute", zIndex: 999, left: 0, right: 0 }}
          >
            {draw_context.scope && (
              <div className={cx("frame-alert", "scope")}>Inside Block</div>
            )}
            {draw_context.current_selection?.[0] &&
              isOverwritten(draw_context.current_selection?.[0]) && (
                <div className={cx("frame-alert", "changed")}>Overwritten</div>
              )}
          </List>
          <CanvasContextProvider>
            <CanvasWindow
              {...canvas_size}
              // onClick={() => {
              //   setDrawContext({
              //     current_selection: [
              //       Object.values(draw_context.designObjects)[0],
              //     ],
              //   });
              //   console.log(Object.values(draw_context.designObjects)[0]);
              // }}
            />
          </CanvasContextProvider>
        </div>
        <Division />
        <div className={cx("frame-bottom")}>
          <CommandFrame />
        </div>
      </List>
    </div>
  );
};

export default DrawSection;
