import {ThicknessMaterialObject} from "../type/GeometryObject"
import IconSymbol from "./IconSymbol";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import PropertyRow from "./PropertyRow";
import {Material} from "../type/Detail";

import styles from "./PropertyGroupForThicknessMaterial.module.scss";
import classNames from "classnames/bind";

import PropertyRowObject from "./PropertyRowObject";

import useProjectContext from "../hooks/useProjectContext";

const cx = classNames.bind(styles);

const PropertyGroupForThicknessMaterial = ({
  data,
}: {
  data: ThicknessMaterialObject;
}) => {
  const {project_context} = useProjectContext();
  const prj = project_context.project;
  // const {
  //   getNamedObject,
  //   updateNamedObject,
  //   updateDesignObject,
  //   getNamedObjectsByType,
  //   getOverriddenNamedObject,
  // } = useDrawContext();
  return (
    <PropertyGroup
      title="Thickness Material"
      actions={[{ icon_type: "assets", illust: "Assets" }]}
    >
      {"name"in data && <PropertyRow>
        <PropertyBlock
          type="long-text"
          name="Name"
          value={data.name}
        />
      </PropertyRow>}
      <PropertyRow>
        <PropertyBlock type="object-input" value={data.thickness}>
          <IconSymbol type="thickness" />
        </PropertyBlock>
        <PropertyBlock
          type="text-input"
          name="Var."
          value={
            data.variable ? "True" : "False"
          }
          italic
        />
        <PropertyBlock type="action" icon_type="none" disabled />
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock type="subgroup">Fill</PropertyBlock>
        <PropertyBlock type="action" icon_type="assets" illust="Assets" />
        <PropertyBlock type="action" icon_type="add" illust="Add" />
      </PropertyRow>
      {prj.get(data.material)?.hatch && (
        <PropertyRowObject
          object={prj.get(data.material)?.hatch}
          onDelete={() => {}}
          onClick={(e) => {
            prj.update<Material>(data.material, {
              hatch: e,
            })
          }}
          onEdit={(e) => {}}
        >
          <IconSymbol type="material" />
        </PropertyRowObject>
      )}

      {prj.get(data.material)?.hatch_layer && (
        <PropertyRow>
          <PropertyBlock
            type="text-selection-fill"
            name="Layer"
            value={
              prj.get(prj.get(data.material)?.hatch_layer).name
            }
          />
        </PropertyRow>
      )}
      <PropertyRow>
        <PropertyBlock type="subgroup">Stroke</PropertyBlock>
        <PropertyBlock type="action" icon_type="assets" illust="Assets" />
        <PropertyBlock type="action" icon_type="add" illust="Add" />
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock
          type="object-selection"
          value={"by Layer"}
          object_type="ColorStyle"
        >
          <div
            className={cx("frame-color")}
            style={{
              backgroundColor: prj.get(
                prj.get(
                  prj.get(data.material)
                  .outline_layer
                ).color
              ).value
            }}
          />
        </PropertyBlock>
        <PropertyBlock type="action" icon_type="delete" illust="Delete" />
      </PropertyRow>
      <PropertyRow>
        <PropertyBlock
          type="text-selection-fill"
          name="Layer"
          value={
            prj.get(
              prj.get(
                data.material
              ).outline_layer
            ).name
          }
        />
      </PropertyRow>
    </PropertyGroup>
  );
};

export default PropertyGroupForThicknessMaterial;
