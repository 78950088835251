import { useState } from "react";
import List from "./List";

import styles from "./NavSection.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconActionBack } from "../svgs/IconActionBack.svg";
import { ReactComponent as IconNavSelect } from "../svgs/IconNavSelect.svg";
import { ReactComponent as IconNavLayers } from "../svgs/IconNavLayers.svg";
import { ReactComponent as IconNavDependencies } from "../svgs/IconNavDependencies.svg";
import { ReactComponent as IconNavAssets } from "../svgs/IconNavAssets.svg";
import { ReactComponent as IconNavStyles } from "../svgs/IconNavStyles.svg";
import Division from "./Division";
import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";
import LayerRow from "./LayerRow";
import { v4 as uuid } from "uuid";
import useProjectContext from "../hooks/useProjectContext";
import { ColorStyle, Layer, LineType, LineWeight } from "../type/Layer";
import { NamedObject } from "../type/DesignatorObject";

const cx = classNames.bind(styles);

const sample_layer_data = [
  {
    name: "Defpoints",
    color: "#b29faf",
    locked: true,
  },
  {
    name: "COL",
    color: "#ff6d99",
  },
  {
    name: "SYM",
    color: "#ffbc6d",
  },
  {
    name: "WID",
    color: "#88eefb",
  },
  {
    name: "WALL",
    color: "#ff6d99",
    current: true,
  },
  {
    name: "I-DIM",
    color: "#ec6dd8",
    hidden: true,
  },
  {
    name: "COR",
    color: "#ff6d99",
  },
  {
    name: "F-FUR",
    color: "#46e860",
    hidden: true,
    locked: true,
  },
  {
    name: "F-PIPE",
    color: "#76eeca",
    hidden: true,
    locked: true,
  },
];

const NavSection = ({}: any) => {
  const { project_context, setProjectContext } = useProjectContext();

  return (
    <div className={cx("wrapper")}>
      <List type="column" attach="start" fill>
        <List
          type="row"
          attach="end"
          padding={0.5}
          fill
          style={{
            minHeight: "2rem",
          }}
        >
          <IconActionBack />
        </List>
        <Division />
        <List type="column" attach="start" align="left" fill>
          <div style={{ width: "100%", minHeight: ".5rem" }}></div>
          <PropertyRow>
            <PropertyBlock type="group-title">Layers</PropertyBlock>
            <PropertyBlock type="action" icon_type="sort" illust="Sort" />
            <PropertyBlock type="action" icon_type="filter" illust="Filter" />
            <PropertyBlock
              type="action"
              icon_type="add"
              illust="Add"
              onClick={() => {
                const new_layer: NamedObject<Layer> = {
                  uuid: uuid(),
                  type: "Layer",
                  name: `New Layer ${
                    project_context.project.layers.all.length + 1
                  }`,
                  activated: true,
                  linetype: project_context.project.makeFixedRef(
                    Object.values(project_context.project.named_objects).filter(
                      (e) => e.type === "LineType"
                    )?.[0] as LineType
                  ),
                  lineweight: project_context.project.makeFixedRef(
                    Object.values(project_context.project.named_objects).filter(
                      (e) => e.type === "LineWeight"
                    )?.[0] as unknown as LineWeight
                  ),
                  color: project_context.project.makeFixedRef(
                    Object.values(project_context.project.named_objects).filter(
                      (e) => e.type === "ColorStyle"
                    )?.[0] as unknown as ColorStyle
                  ),
                };
                project_context.project.add(new_layer);
                // addNamedObject(new_layer);
                // setDrawContext({
                //   layers: {
                //     all: [...draw_context.layers.all, abstractify(new_layer)],
                //     current: draw_context.layers.current,
                //   },
                // });
              }}
            />
          </PropertyRow>
          {project_context.project.layers.all.map((layer) => (
            <LayerRow
              layer={layer}
              key={layer.uuid}
              current={
                layer.uuid === project_context.project.layers.current.uuid
              }
              onChange={(event) => {
                console.log(event);
                project_context.project.update(layer, {
                  name: event.target?.value,
                });
                // updateNamedObject(layer, { name: event.target?.value });
              }}
              onClick={() =>
                setProjectContext({
                  current_selection: [
                    project_context.project.makeFixedRef(layer),
                  ],
                })
              }
              clicked={
                project_context.current_selection.filter(
                  (s) => s.uuid === layer.uuid
                ).length > 0
              }
            />
          ))}
        </List>
        <Division />
        <div style={{ minHeight: "64px" }}>
          {/* <List type="column" attach="start" style={{ height: "2rem" }}> */}

          {/* </List> */}
        </div>
      </List>
    </div>
  );
};

export default NavSection;
