import {
  drawingPolygonFill,
  drawingCircleRadius,
  drawingColor,
  drawingLineWidth,
  drawingTriangleRadius,
  drawingTriangleOffset,
  selectedColor,
  fadedOpacity,
  clickAreaWidth,
  scopeColor,
} from "../utils/CanvasConstants";
import {
  getAngle,
  isVector3Valid,
  offsetSegment,
  rotateVector,
  unitVector,
} from "../utils/GeomUtils";
import { SelectedDesignObject } from "../components/Canvas";
import {
  getSnappedPoint,
  layerToLinestyle,
  leftClickOnly,
} from "../utils/CanvasUtils";
import {
  Circle,
  Group,
  Polyline,
  RegularPolygon,
  Polygon,
} from "../utils/Geometry";
import DetailObject from "./DetailObject";
import useCanvasContext from "../components/CanvasContext";
import { getOffset } from "../utils/DetailUtils";
import { useEffect, useMemo, useState } from "react";
import { DrawnObject } from "../type/Project";
import { isObjectOfType } from "../type/DesignatorObject";
import { _3DLineObject, ThicknessMaterialObject } from "../type/GeometryObject";
import useProjectContext from "../hooks/useProjectContext";
import * as THREE from "three";
import { CrossSection } from "../type/Detail";

export const EditingObject = ({
  current_points,
  is_scope = false,
  editable,
  drawn_object,
  scale,
}: {
  current_points: THREE.Vector3[];
  is_scope: boolean;
  editable: boolean;
  drawn_object?: DrawnObject;

  scale?: number;
}) => {
  const { canvasContext, setCanvasContext } = useCanvasContext();
  const { project_context, setProjectContext } = useProjectContext();
  const [editing, setEditing] = useState<number>(-1);
  const [hover, setHover] = useState<number>(-1);

  const handleMouseDown = leftClickOnly((event, idx) => {
    setEditing(idx);
  });

  useEffect(() => {
    const handleMouseUp = () => {
      setEditing(-1);
    };
    const handleMousemove = (e) => {
      if (editing >= 0) {
        const line = drawn_object as _3DLineObject;
        if (line) {
          const pos = getSnappedPoint(
            e,
            canvasContext.camera,
            canvasContext.baseLinePoints,
            canvasContext.scale
          );
          let point_index = editing as number;

          let new_line_geometry = structuredClone(line.geometry);
          new_line_geometry.points = line.geometry.points
            .slice(0, point_index)
            .concat([pos])
            .concat(line.geometry.points.slice(point_index + 1));

          project_context.project.update<DrawnObject>(line, {
            geometry: new_line_geometry,
          });
        }
      }
    };
    window.addEventListener("mousemove", handleMousemove);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMousemove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [editing]);

  // current points empty
  if (current_points.length < 2) {
    return;
  }

  // styles
  const line_style = {
    stroke: is_scope ? scopeColor : drawingColor,
    strokeWidth: drawingLineWidth,
  };

  const circle_style = {
    stroke: is_scope ? scopeColor : drawingColor,
    strokeWidth: drawingLineWidth,
    fill: drawingPolygonFill,
    radius: drawingCircleRadius,
  };

  const triangle_style = {
    stroke: is_scope ? scopeColor : drawingColor,
    strokeWidth: drawingLineWidth,
    fill: drawingPolygonFill,
    radius: drawingTriangleRadius,
  };

  const line = (
    <Polyline
      points={current_points}
      {...line_style}
      strokeScaleEnabled={false}
    />
  );

  const circle = (
    <Circle
      x={current_points[0].x}
      y={current_points[0].y}
      {...circle_style}
      scaleX={1 / (scale ?? canvasContext.scale)}
      scaleY={1 / (scale ?? canvasContext.scale)}
      fill={hover === 0 || editing == 0 ? line_style.stroke : "white"}
      strokeScaleEnabled={false}
      onPointerEnter={() => setHover(0)}
      onPointerLeave={() => setHover(-1)}
      onPointerDown={(e) => handleMouseDown(e, 0)}
      listening={editable}
      hitStrokeWidth={20}
    />
  );

  if (current_points.length < 2) {
    return <Group>{circle}</Group>;
  }

  const vector = unitVector(current_points[0], current_points[1]);

  // check for NaN
  if (!isVector3Valid(vector)) {
    return;
  }

  const normal = rotateVector(vector, Math.PI / 2, "z", true);
  const rot = getAngle(
    [new THREE.Vector3(0, 0, 0), new THREE.Vector3(1, 0, 0)],
    [current_points[0], current_points[1]]
  );

  const tri2point = current_points[0]
    .clone()
    .add(
      normal.multiplyScalar(
        drawingTriangleOffset / (scale ?? canvasContext.scale)
      )
    );

  const triangle1 = (
    <RegularPolygon
      sides={3}
      x={current_points[1].x}
      y={current_points[1].y}
      rotation={rot}
      {...triangle_style}
      scaleX={1 / (scale ?? canvasContext.scale)}
      scaleY={1 / (scale ?? canvasContext.scale)}
      strokeScaleEnabled={false}
      scaleDisabled={true}
      design_object={drawn_object}
      line_index={1}
      fill={hover === 1 || editing === 1 ? line_style.stroke : "white"}
      onPointerEnter={() => setHover(1)}
      onPointerLeave={() => setHover(-1)}
      onPointerDown={(e) => handleMouseDown(e, 1)}
      listening={editable}
      hitStrokeWidth={20}
    />
  );

  const triangle2 = (
    <RegularPolygon
      sides={3}
      x={tri2point.x}
      y={tri2point.y}
      rotation={rot + 90}
      {...triangle_style}
      scaleX={1 / (scale ?? canvasContext.scale)}
      scaleY={1 / (scale ?? canvasContext.scale)}
      strokeScaleEnabled={false}
      scaleDisabled={true}
    />
  );

  return (
    <Group>
      {line}
      {circle}
      {triangle1}
      {triangle2}
    </Group>
  );
};

export const BaseLine = ({
  line_object,
  style_override,
}: {
  line_object: _3DLineObject;
  style_override: Object;
}) => {
  const { project_context } = useProjectContext();
  const layer = useMemo(
    () => project_context.project.get(line_object.layer),
    [line_object.layer, project_context.project]
  );
  const linestyle = useMemo(
    () => layerToLinestyle(layer, project_context.project),
    [layer, project_context.project]
  );

  return (
    <Polyline
      design_object={line_object}
      points={line_object.geometry.points}
      {...linestyle}
      {...style_override}
      strokeScaleEnabled={
        project_context.project.global_setting.rendering_style === "print"
      }
    />
  );
};

export const ClickArea = ({
  design_object,
  is_locked,
}: {
  design_object: _3DLineObject;
  is_locked: boolean;
}) => {
  const { project_context, setProjectContext } = useProjectContext();
  const { canvasContext } = useCanvasContext();

  const area = useMemo(() => {
    let seg = design_object.geometry.points;

    let cross_section = project_context.project.get(
      design_object.details?.[0]
    ) as CrossSection;

    let thickness_materials = cross_section?.materials
      ?.getElements()
      .map(project_context.project.get) as ThicknessMaterialObject[];

    let offsets = cross_section
      ? getOffset(cross_section, thickness_materials)
      : null;
    let dist1 = cross_section
      ? offsets[0][0]
      : clickAreaWidth / 2 / canvasContext.scale;
    let dist2 = cross_section
      ? offsets[offsets.length - 1][1]
      : -clickAreaWidth / 2 / canvasContext.scale;
    let offseg1 = offsetSegment(seg[0], seg[1], dist1);
    let offseg2 = offsetSegment(seg[0], seg[1], dist2);

    offseg2 = [offseg2[1], offseg2[0]];
    let area = offseg1.concat(offseg2);
    return area;
  }, [design_object, is_locked]);

  // event listeners

  const handleLineClick = leftClickOnly((e) => {
    // let design_object = getDesignObject(e.target.attrs.design_object);
    if (!e.eventObject?.design_object) return;
    let design_object = project_context.project.get(
      e.eventObject.design_object
    );

    if (project_context.scope === null && design_object) {
      setProjectContext({
        mode: "edit",
        current_selection: [
          project_context.project.makeFixedRef(design_object),
        ],
      });
    }
  });

  const handleLineDblClick = leftClickOnly((e) => {
    // let design_object = getDesignObject(e.target.attrs.design_object);
    let design_object = project_context.project.get(
      e.eventObject.design_object
    );

    if (project_context.scope === null && design_object) {
      setProjectContext({
        mode: "edit",
        scope: project_context.project.makeFixedRef(design_object),
        current_selection: [],
      });
    }
  });

  return (
    <Polygon
      design_object={project_context.project.makeFixedRef(design_object)}
      points={area}
      closed={true}
      stroke={null}
      fill={null}
      strokeScaleEnabled={false}
      onClick={is_locked ? null : handleLineClick}
      onDoubleClick={is_locked ? null : handleLineDblClick}
    />
  );
};

export const CanvasDrawnObject = ({
  drawn_object: drawn_object,
}: {
  drawn_object: DrawnObject;
}) => {
  const { project_context, setProjectContext } = useProjectContext();

  // only LineObject for now
  if (!isObjectOfType<_3DLineObject>(drawn_object, "_3DLineObject")) {
    return;
  }

  let layer = project_context.project.get(drawn_object.layer);
  if (!layer.activated) {
    return;
  }
  // console.log(design_object.details?.[0]?.internalDetails?.[0].uuid);

  // is locked
  const is_locked = layer.locked;

  // is selected
  const is_selected =
    SelectedDesignObject(project_context)?.uuid === drawn_object.uuid;

  // is editing
  const is_editing = is_selected && project_context.mode === "edit";

  // is scope
  const is_scope = project_context.scope?.uuid === drawn_object.uuid;

  // is faded
  const is_faded = !is_scope && project_context.scope !== null;

  // has detail
  const has_detail = drawn_object.details.length > 0;

  // style override
  let style_override = {};
  if (is_faded) {
    style_override["opacity"] = fadedOpacity;
  }
  if (is_selected) {
    style_override["stroke"] = selectedColor;
    style_override["opacity"] = has_detail ? 1 : 0;
    style_override["fill"] = selectedColor;
  }

  return (
    <Group
      design_object={drawn_object}
      layer={project_context.project.makeFixedRef(drawn_object.layer)}
      opacity={is_faded ? fadedOpacity : 1}
      // onClick={is_locked ? null : handleLineClick}
      // onDblClick={is_locked ? null : handleLineDblClick}
    >
      <ClickArea design_object={drawn_object} is_locked={is_locked} />

      {has_detail ? (
        <DetailObject
          drawn_object={drawn_object}
          detail={drawn_object.details[0]}
          selected={is_selected}
          is_locked={is_locked}
          // disabled={is_faded}
        />
      ) : (
        <BaseLine line_object={drawn_object} style_override={style_override} />
      )}
      {is_editing && (
        <>
          <EditingObject
            current_points={drawn_object.geometry.points}
            is_scope={false}
            editable
            drawn_object={drawn_object}
          />
          {/* <BasePoint
            design_object={design_object}
            style_override={style_override}
            is_locked={is_locked}
          /> */}
        </>
      )}
      {is_scope && (
        <EditingObject
          current_points={drawn_object.geometry.points}
          is_scope={true}
          editable
          drawn_object={drawn_object}
        />
      )}
    </Group>
  );
};
