import { useMemo } from "react";
// import {
// //   AbstractDesignatorObject,
// //   DesignObject,
//   EasyJoin,
//   InternalDetail,
//   LineObject,
//   Material,
//   NamedObject,
//   UUID,
// } from "../hooks/useDrawContext";
import { v4 as uuid } from "uuid";
import { DrawnObject } from "../type/Project";
import {
  _3DLineObject,
  GeometryObject,
  GeometryObjectGroup,
} from "../type/GeometryObject";
import {
  DesignatorObject,
  FixedRef,
  NamedRef,
  Abstract,
  NamedObject,
} from "../type/DesignatorObject";
import { Layer } from "../type/Layer";

export const isNamedObject = (
  obj: DesignatorObject
): obj is NamedObject<DesignatorObject> => {
  return "name" in obj && !isNamedRef(obj);
};

export const isNamedRef = <T extends DesignatorObject>(
  obj: T | NamedRef<T>
): obj is NamedRef<T> => {
  return "name" in obj && "ref_uuid" in obj;
};

// export const isDrawnObject = (
//   obj: DesignatorObject
// ): obj is DrawnObject => {
//   return obj?.type === "DesignObject";
// };

// export const isLineObject = (obj: DesignObject): obj is LineObject => {
//   return isDrawnObject(obj) && obj.designObjectType === "LineObject";
// };

// export const is3DLineObject = (obj: DrawnObject): obj is _3DLineObject => {
//   return obj.type === "_3DLineObject";
// };

// export const isInternalDetail = (obj: NamedObject): obj is InternalDetail => {
//   return isNamedObject(obj) && obj.type === "InternalDetail";
// };

// export const priorityOfMaterial = (
//   easyjoin: EasyJoin,
//   material: Material | Abstract<Material>
// ) => {
//   return easyjoin.priorities.findIndex((x) => x.uuid === material.uuid);
// };

function isValidHex(hex) {
  // Allow hex both with and without '#'
  return /^#?([A-Fa-f0-9]{3}){1,2}$/.test(hex);
}

function isValidRGBArray(rgbArray) {
  // Check if it's three numbers between 0 and 255, optionally separated by spaces or commas
  return rgbArray.every((val) => val >= 0 && val <= 255);
}

function rgbToHex(r, g, b) {
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

function convertColor(input) {
  input = input.trim(); // Remove any leading or trailing spaces

  // Check if input is a valid hex color (with or without '#')
  if (isValidHex(input)) {
    // Add '#' if it's missing
    if (!input.startsWith("#")) {
      input = "#" + input;
    }
    return input.toUpperCase();
  }

  // Check if input is in RGB format like 'rgb(255, 87, 51)'
  const rgbMatch = input.match(
    /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
  );
  if (rgbMatch) {
    const r = parseInt(rgbMatch[1]);
    const g = parseInt(rgbMatch[2]);
    const b = parseInt(rgbMatch[3]);

    if (isValidRGBArray([r, g, b])) {
      return rgbToHex(r, g, b);
    }
  }

  // Check if input is just three numbers (e.g., '255, 87, 51' or '255 87 51')
  const rgbArray = input.split(/[\s,]+/).map(Number);
  if (rgbArray.length === 3 && isValidRGBArray(rgbArray)) {
    return rgbToHex(rgbArray[0], rgbArray[1], rgbArray[2]);
  }

  return null; // Invalid color input
}

export const getCorrectColor = (str: string, alt: string) => {
  return convertColor(str) ?? alt;
};

const evaluateScaleDenominator = (input: string) => {
  try {
    // Trim and remove any spaces from the input
    input = input.replace(/\s+/g, "").trim();

    // Check if input is in the form of 1/x
    const fractionMatch = input.match(/^1\/(\d+(\.\d+)?)$/);
    if (fractionMatch) {
      return parseFloat(fractionMatch[1]); // Return the denominator as is
    }

    // Evaluate simple mathematical expressions like 3+5, 2*10, etc.
    const evaluated = eval(input); // Caution: eval can execute arbitrary code, ensure proper validation for real use cases

    // If the result is a number, check the conditions
    if (typeof evaluated === "number" && !isNaN(evaluated)) {
      if (evaluated > 1) {
        return evaluated; // Return the number as is if it's greater than 1
      } else if (evaluated > 0) {
        return 1 / evaluated; // Return the inverse if it's less than 1 but greater than 0
      }
    }

    return null; // Return null for invalid input or cases that don't match
  } catch (error) {
    return null; // Return null if there's any error during evaluation
  }
};

export const getScaleDenominator = (str: string, alt: number) => {
  return evaluateScaleDenominator(str) ?? alt;
};

// export const getNewAbstractDesignatorObject = (
//   obj: NamedObject | DesignObject,
//   dep = []
// ): AbstractDesignatorObject => {
//   const instance_uuid = uuid();
//   return { type: obj.type, uuid: obj.uuid, instance_uuid };
// };

// export const getNewUUid = (): UUID => {
//   return uuid();
// };

///TO REMOVE
// export const abstractify = <T extends AbstractDesignatorObject>(
//   obj: T
// ): Abstract<T> => {
//   if (obj === undefined) {
//     return obj;
//   }
//   const { uuid, type } = obj;
//   const new_uuid: UUID = getNewUUid();

//   return { uuid: new_uuid, ref_uuid: uuid, type } as Abstract<T>;
// };

// export const isDrawnNamedRef = (
//   obj: DrawnObject
// ): obj is NamedRef<GeometryObject | GeometryObjectGroup> => {
//   return "name" in obj;
// };

export const isLayer = (obj: DesignatorObject): obj is Layer => {
  return obj.type === "Layer";
};

export const isFixedRef = <T extends DesignatorObject>(
  obj: Abstract<T>
): obj is FixedRef<T> => {
  return Object.keys(obj).length === 2 && "uuid" in obj && "type" in obj;
};
