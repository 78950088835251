import { useMemo, useRef } from "react";
import * as THREE from "three";
import useCanvasContext from "../components/CanvasContext";

interface BuildLineOptions {
  joinStyle?: "miter" | "bevel" | "round";
  closed?: boolean;
  miterLimit?: number; // miter limit 값 (기본: 4 정도)
}

export function buildLineGeometry(
  points: THREE.Vector3[],
  strokeWidth: number,
  options?: BuildLineOptions
): THREE.BufferGeometry {
  const joinStyle = options?.joinStyle ?? "round";
  let closed = options?.closed ?? false;
  const miterLimit = options?.miterLimit ?? 4;

  if (points.length >= 2) {
    const first = points[0];
    const last = points[points.length - 1];
    if (first.x === last.x && first.y === last.y) {
      points = points.slice(0, -1);
      closed = true;
    }
  }

  const n = points.length;
  if (n < 2) {
    return new THREE.BufferGeometry();
  }

  const segmentCount = closed ? n : n - 1;
  const halfWidth = strokeWidth * 0.5;

  const positions: number[] = [];

  function leftNormal(v: THREE.Vector2): THREE.Vector2 {
    return new THREE.Vector2(-v.y, v.x);
  }
  function rightNormal(v: THREE.Vector2): THREE.Vector2 {
    return new THREE.Vector2(v.y, -v.x);
  }

  function pushSegment(p1: THREE.Vector3, p2: THREE.Vector3) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    const len = Math.sqrt(dx * dx + dy * dy);
    if (len === 0) return;

    const nx = -dy / len;
    const ny = dx / len;

    const lx1 = p1.x + nx * halfWidth;
    const ly1 = p1.y + ny * halfWidth;
    const rx1 = p1.x - nx * halfWidth;
    const ry1 = p1.y - ny * halfWidth;

    const lx2 = p2.x + nx * halfWidth;
    const ly2 = p2.y + ny * halfWidth;
    const rx2 = p2.x - nx * halfWidth;
    const ry2 = p2.y - ny * halfWidth;

    positions.push(
      lx1,
      ly1,
      0,
      rx1,
      ry1,
      0,
      lx2,
      ly2,
      0,
      lx2,
      ly2,
      0,
      rx1,
      ry1,
      0,
      rx2,
      ry2,
      0
    );
  }

  function pushTriangle(
    ax: number,
    ay: number,
    bx: number,
    by: number,
    cx: number,
    cy: number
  ) {
    positions.push(ax, ay, 0, bx, by, 0, cx, cy, 0);
  }

  function intersectLines(
    A: THREE.Vector2,
    D1: THREE.Vector2,
    B: THREE.Vector2,
    D2: THREE.Vector2
  ): THREE.Vector2 | null {
    const cross = D1.x * D2.y - D1.y * D2.x;
    if (Math.abs(cross) < 1e-7) return null;
    const dx = B.x - A.x;
    const dy = B.y - A.y;
    const t = (dx * D2.y - dy * D2.x) / cross;
    return A.clone().add(D1.clone().multiplyScalar(t));
  }

  function computeMiterIntersection(
    corner: THREE.Vector2,
    prevDir: THREE.Vector2,
    nextDir: THREE.Vector2,
    outwardPrev: THREE.Vector2,
    outwardNext: THREE.Vector2
  ): THREE.Vector2 | null {
    const A = corner.clone().add(outwardPrev);
    const D1 = prevDir.clone();
    const B = corner.clone().add(outwardNext);
    const D2 = nextDir.clone();
    return intersectLines(A, D1, B, D2);
  }

  function pushJoin(
    cornerX: number,
    cornerY: number,
    prevDir: THREE.Vector2,
    nextDir: THREE.Vector2,
    joinStyle: "miter" | "bevel" | "round"
  ) {
    const corner = new THREE.Vector2(cornerX, cornerY);
    const crossVal = prevDir.x * nextDir.y - prevDir.y * nextDir.x;

    let outwardPrev: THREE.Vector2;
    let outwardNext: THREE.Vector2;
    if (crossVal > 0) {
      outwardPrev = rightNormal(prevDir).multiplyScalar(halfWidth);
      outwardNext = rightNormal(nextDir).multiplyScalar(halfWidth);
    } else {
      outwardPrev = leftNormal(prevDir).multiplyScalar(halfWidth);
      outwardNext = leftNormal(nextDir).multiplyScalar(halfWidth);
    }

    switch (joinStyle) {
      case "bevel": {
        const pA = corner.clone().add(outwardPrev);
        const pB = corner.clone().add(outwardNext);
        pushTriangle(corner.x, corner.y, pA.x, pA.y, pB.x, pB.y);
        break;
      }
      case "round": {
        const segments = 8;
        const pA = corner.clone().add(outwardPrev);
        const pB = corner.clone().add(outwardNext);
        const angleA = Math.atan2(pA.y - corner.y, pA.x - corner.x);
        const angleB = Math.atan2(pB.y - corner.y, pB.x - corner.x);
        let delta = angleB - angleA;
        while (delta < 0) delta += Math.PI * 2;
        while (delta > Math.PI * 2) delta -= Math.PI * 2;
        let prevX = pA.x;
        let prevY = pA.y;
        for (let i = 1; i <= segments; i++) {
          const t = i / segments;
          const theta = angleA + delta * t;
          const cx = corner.x + Math.cos(theta) * halfWidth;
          const cy = corner.y + Math.sin(theta) * halfWidth;
          pushTriangle(corner.x, corner.y, prevX, prevY, cx, cy);
          prevX = cx;
          prevY = cy;
        }
        break;
      }
      case "miter":
      default: {
        const miterPoint = computeMiterIntersection(
          corner,
          prevDir,
          nextDir,
          outwardPrev,
          outwardNext
        );
        if (
          !miterPoint ||
          miterPoint.distanceTo(corner) / halfWidth > miterLimit
        ) {
          const pA = corner.clone().add(outwardPrev);
          const pB = corner.clone().add(outwardNext);
          pushTriangle(corner.x, corner.y, pA.x, pA.y, pB.x, pB.y);
        } else {
          const cornerA = corner.clone().add(outwardPrev);
          const cornerB = corner.clone().add(outwardNext);
          pushTriangle(
            corner.x,
            corner.y,
            cornerA.x,
            cornerA.y,
            miterPoint.x,
            miterPoint.y
          );
          pushTriangle(
            corner.x,
            corner.y,
            miterPoint.x,
            miterPoint.y,
            cornerB.x,
            cornerB.y
          );
        }
        break;
      }
    }
  }

  let prevDir: THREE.Vector2 | null = null;
  let firstDir: THREE.Vector2 | null = null;
  let firstCorner: THREE.Vector3 | null = null;

  for (let i = 0; i < segmentCount; i++) {
    const a = points[i];
    const b = points[(i + 1) % n];

    pushSegment(a, b);

    const dir = new THREE.Vector2(b.x - a.x, b.y - a.y).normalize();

    if (i === 0) {
      firstDir = dir.clone();
      firstCorner = a.clone();
    }

    if (i > 0 && prevDir) {
      pushJoin(a.x, a.y, prevDir, dir, joinStyle);
    }

    prevDir = dir;
  }

  if (closed && prevDir && firstDir && firstCorner) {
    pushJoin(firstCorner.x, firstCorner.y, prevDir, firstDir, joinStyle);
  }

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(positions, 3)
  );
  if (positions.some((n) => isNaN(n))) {
    console.error("NaN found in positions:", positions);
  }
  geometry.computeBoundingBox();
  geometry.computeBoundingSphere();
  return geometry;
}

// 3. R3fLine 컴포넌트
export const CustomLine = ({
  points,
  stroke,
  strokeWidth = 2,
  opacity = 1,
  strokeScaleEnabled = true,
  options = {},
  ...props
}: {
  points: THREE.Vector3[];
  stroke: string;
  strokeWidth?: number;
  strokeScaleEnabled?: boolean;
  options?: BuildLineOptions;
  opacity?: number;
}) => {
  // const hasNaN = points.some(
  //   (v) =>
  //     !Number.isFinite(v.x) || !Number.isFinite(v.y) || !Number.isFinite(v.z)
  // );

  // if (hasNaN) {
  //   throw new Error("Vector3 array contains NaN or invalid values");
  // }

  const meshRef = useRef<THREE.Mesh>(null!);
  const { canvasContext } = useCanvasContext();

  const geometry = useMemo(() => {
    return buildLineGeometry(
      points,
      strokeScaleEnabled ? strokeWidth : strokeWidth / canvasContext.scale,
      options
    );
  }, [points, strokeWidth, canvasContext.scale, strokeScaleEnabled]);

  return (
    <mesh ref={meshRef} geometry={geometry} {...props}>
      <meshBasicMaterial
        color={stroke}
        side={THREE.DoubleSide}
        transparent={opacity < 1}
        opacity={opacity}
      />
    </mesh>
  );
};
