import { Group, Line } from "react-konva";
import useDrawContext, {
  DesignatorLayer,
  DesignObject,
  HatchType,
  Material,
} from "../hooks/useDrawContext";
import { abstractify, isLineObject } from "../utils/ObjectUtils";
import { layerToLinestyle } from "../utils/KonvaUtils";
import Hatch from "./Hatch";
import { useEffect, useMemo } from "react";
import { fadedOpacity, selectedColor } from "../utils/CanvasConstants";
import { getXYCrossing } from "../utils/GeomUtils";

const MaterialObject = ({
  design_object,
  boundary,
  active_sides,
  material,
  selected,
  disabled,
}: {
  design_object: DesignObject;
  boundary: number[];
  active_sides: boolean[];
  material: Material;
  selected: boolean;
  disabled?: boolean;
}) => {
  const { draw_context, getNamedObject } = useDrawContext();

  // check layer activated
  let design_object_layer = getNamedObject(design_object.layer);

  // outline
  const outline_layer = useMemo(
    () => getNamedObject(material.outlineLayer),
    [material, getNamedObject]
  );
  const linestyle = useMemo(
    () =>
      layerToLinestyle(
        outline_layer,
        getNamedObject,
        draw_context.global_setting
      ),
    [outline_layer, getNamedObject, draw_context]
  );

  let closed_boundary = boundary.concat(boundary.slice(0, 2));
  let active_segments: number[][] = [];

  for (let i = 0; i < active_sides.length; i++) {
    if (!active_sides[i]) {
      continue;
    }
    active_segments.push(closed_boundary.slice(2 * i, 2 * (i + 2)));
  }

  const SegmentLine = ({ segment }: { segment: number[] }) => {
    return (
      <Line
        layer={abstractify(outline_layer)}
        points={segment}
        {...linestyle}
        {...(selected ? { stroke: selectedColor } : {})}
        strokeScaleEnabled={
          draw_context.global_setting.rendering_style === "print"
        }
      />
    );
  };

  // hatch
  const hatch: HatchType = getNamedObject(material.hatch);
  const hatch_layer: DesignatorLayer = getNamedObject(material.hatchLayer);

  // useEffect(() => {
  //   const d = hatch.value;
  // }, []);

  const hatch_props = useMemo(
    () => ({
      type: hatch?.value,
      boundary,
      pivot_point: hatch?.value.includes("Ins")
        ? getXYCrossing(
            [boundary[0], boundary[1]],
            [boundary[2] - boundary[0], boundary[3] - boundary[1]]
          )
        : [0, 0],
      unit_direction: hatch?.value.includes("Ins")
        ? [boundary[2] - boundary[0], boundary[3] - boundary[1]]
        : [1, 0],
      scale: hatch?.value.includes("Ins")
        ? (material.thickness ?? 1) / 100
        : hatch?.scale ?? 1,
      layer: abstractify(hatch_layer),
      // debug: hatch?.value.includes("Ins"),
    }),
    [boundary, hatch, hatch_layer]
  );

  return (
    <Group opacity={disabled ? fadedOpacity : 1}>
      {selected && (
        <Line
          points={boundary}
          fill={selectedColor}
          strokeWidth={0}
          opacity={0.05}
          closed={true}
          listening={false}
        />
      )}

      {design_object_layer.activated && hatch_layer?.activated && (
        <Hatch {...hatch_props} selected={selected} />
      )}
      {design_object_layer.activated &&
        outline_layer.activated &&
        active_segments.map((segment, idx) => (
          <SegmentLine key={idx} segment={segment} />
        ))}
    </Group>
  );
};

export default MaterialObject;
