import IconSymbol from "./IconSymbol";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import PropertyRow from "./PropertyRow";

import styles from "./PropertyGroupForDetail.module.scss";
import classNames from "classnames/bind";

import { Fragment } from "react/jsx-runtime";
import DropdownCommand from "./DropdownCommand";
import PropertyRowObject from "./PropertyRowObject";

import StandardSlabImg from "../imgs/standard_slab.png";
import { _3DLineObject } from "../type/GeometryObject";
import { Abstract, isObjectOfType } from "../type/DesignatorObject";
import useProjectContext from "../hooks/useProjectContext";
import { CrossSection } from "../type/Detail";

const cx = classNames.bind(styles);

const PropertyGroupForDetail = ({ data }: { data: _3DLineObject }) => {
  const { project_context, setProjectContext } = useProjectContext();
  return (
    <PropertyGroup
      title="Detail"
      actions={[
        { icon_type: "assets", illust: "Assets" },
        data.details.length === 0
          ? {
              icon_type: "add",
              illust: "Add",
              onClick: () => {
                project_context.project.update<_3DLineObject>(data, {
                  details: [
                    project_context.project.makeRef<CrossSection>(
                      project_context.project.getNamedObjectsByTypeOf<CrossSection>(
                        "CrossSection"
                      )?.[0]
                    ),
                  ],
                });
              },
            }
          : {
              icon_type: "delete",
              illust: "Delete",
              onClick: () => {
                project_context.project.update<_3DLineObject>(data, {
                  details: [],
                });
              },
            },
      ]}
    >
      <PropertyBlock type="action" icon_type="add" illust="Add" use_dropdown>
        <DropdownCommand
          onClick={() => {
            project_context.project.update<_3DLineObject>(data, {
              details: [
                ...data.details,
                project_context.project.makeRef<CrossSection>(
                  project_context.project.getNamedObjectsByTypeOf<CrossSection>(
                    "CrossSection"
                  )?.[0]
                ),
              ],
            });
          }}
        >
          Add Cross Sections
        </DropdownCommand>
        <DropdownCommand>Add Repeated Objects</DropdownCommand>
        <DropdownCommand>Add Aligned Objects</DropdownCommand>
        <DropdownCommand>Add Free Internal Detail</DropdownCommand>
      </PropertyBlock>
      {data.details
        .filter((detail) =>
          isObjectOfType<Abstract<CrossSection>>(detail, "CrossSection")
        )
        .map((cs, cs_idx) => (
          <Fragment key={cs_idx}>
            {cs_idx === 0 && (
              <PropertyRow small>
                <PropertyBlock type="property-name-group">
                  Cross Section
                </PropertyBlock>
              </PropertyRow>
            )}
            <PropertyRowObject
              object={cs as Abstract<CrossSection>}
              onDelete={() => {
                project_context.project.update<_3DLineObject>(data, {
                  details: data.details.filter((d, d_idx) => d_idx !== cs_idx),
                });
              }}
              onClick={(e) => {
                project_context.project.update<_3DLineObject>(data, {
                  details: data.details.map((d, d_idx) =>
                    d_idx !== cs_idx ? d : e
                  ),
                });
              }}
              onEdit={(e) => {
                project_context.project.update<_3DLineObject>(data, {
                  details: data.details.map((d, d_idx) =>
                    d_idx !== cs_idx ? d : e
                  ),
                });
              }}
            >
              <img src={StandardSlabImg} />
            </PropertyRowObject>
          </Fragment>
        ))}
      {/* <DropdownCommand>
                Add Easy Join
              </DropdownCommand>
              <DropdownCommand>Add Aligned Objects</DropdownCommand>
              <DropdownCommand>Add Free Boundary Detail</DropdownCommand> */}
    </PropertyGroup>
  );
};

export default PropertyGroupForDetail;
